import { combineReducers } from 'redux';

import {agendamentos} from './reducers/agendamentos';
import {profissional} from './reducers/profissional';
import {prontuario} from './reducers/prontuario';
import {configuracao} from './reducers/configuracao';
import {repasses} from './reducers/repasses';
import {painel} from './reducers/Painel';

const reducer = combineReducers({
    agendamentos:agendamentos,
    profissional:profissional,
    prontuario:prontuario,
    configuracao:configuracao,
    repasses:repasses,
    painel:painel
});

export default reducer;
