import React from "react";

import { useState } from 'react';

import M from "materialize-css";
import { Icon } from 'react-materialize';

export default function UiCardTratamentoNaoAutorizado(props) { 
    const [tratamento,setTratamento]=useState(props.tratamento?props.tratamento[0]:{})

    const stly={
            display:'flex',
            marginBotton:'50px'
    }
    return(
        <div className="card">
            <div className="card-content content-trata">
                <div className="card-info card-trata">
                    <span style={{stly}}>{tratamento.codigo_referencia?tratamento.codigo_referencia:null} - {tratamento.titulo?tratamento.titulo:null}</span>
                    <span>DENTE/REGIÃO: {tratamento.dente_regiao?tratamento.dente_regiao:null}</span>
                    <span>FACE: {tratamento.faces?tratamento.faces:null}</span>
                    {/* <span>REAL.:{tratamento.realizado} - AUT.:{tratamento.autorizado}</span> */}
                </div>
            </div>
        </div>
    );
}