import { URL_API } from '../../../constantes/constantes';

const types={
    FETCH_REPASSES_COMISSOES:"FETCH_REPASSES_COMISSOES",
    FETCH_REPASSE_AUTORIZADO:"FETCH_REPASSE_AUTORIZADO",
    // FETCH_DETALHE_REPASSE:"FETCH_DETALHE_REPASSE",
    // FETCH_CONVENIOS:"FETCH_CONVENIOS",
};

const INITIAL_STATE={
    repasses:[],
    autorizados:[],
    // detalhe_repasse:{},
    // convenios:[],
};


export function repasses(state = INITIAL_STATE, action){
    switch(action.type){
        case types.FETCH_REPASSES_COMISSOES:
            return {...state, repasses:action.payload};
        case types.FETCH_REPASSE_AUTORIZADO:
            return {...state, autorizados:action.payload};
        // case types.FETCH_DETALHE_REPASSE:
        //     return {...state, detalhe_repasse:action.payload};
        // case types.FETCH_CONVENIOS:
        //     return {...state, convenios:action.payload};
        default:
            return state;
    }
    
}



//Retornar repasses profissional
export const fetchRepassesProfissional = async(status,page)=>{
    let auth = JSON.parse(localStorage.getItem('auth'));

    let pessoa_id = auth.pessoa_id;
    // let pessoa_id = 233 ;
    let empresa_id=1;
    // let status=2;
    // let page = 1
 
    

    let repasses = await fetch(`${URL_API}/repasses/aguardando?empresa_id=${empresa_id}&page=${page}&pessoa_id=${pessoa_id}&status=${status}`,{
        headers:{
            "Accept": "aplication/json",
            "Content-Type": "application/json",
            "X-Auth-Token": auth.token
        },
        method:"get"
    })
    .then(res=>res.json())
    .then((resJson)=>{
        if(resJson.status=='falha'){
            alert(resJson.menssagem);
            return[];
        }else{
            return resJson.data;
        }
    }).catch(err=>{
        alert("Houveram erros na execução");
        console.log(err);
        return[];
    });
    return repasses;
}

export const fetchAutorizados = async()=>{//pessoa_id, empresa_id
    let auth = JSON.parse(localStorage.getItem('auth'));
    let clinicas = JSON.parse(localStorage.getItem('empresa'))

    let pessoa_id = auth.pessoa_id;
    // let pessoa_id=233;
    // let empresa_id = 1;
    let empresa_id = clinicas.id

    let autorizados = await fetch(`${URL_API}/repasses/${pessoa_id}/autorizados?empresa_id=${empresa_id}`,{
        headers:{
            "Accept":"application/json",
            "Content-Type":"application/json",
            "X-Auth-Token":auth.token
        },
        method:'get'
    })
    .then(res=>res.json())
    .then((resJson)=>{
        if(resJson.status=='falha'){
            alert(resJson.menssagem);
            return[];
        }else{
            return resJson.data;
        }
    }).catch(err=>{
        alert("hoveram erros na execução");
        console.log(err);
        return[];
    });

    return autorizados;
}
// export const fetchCovenios=async ()=>{
//     let auth=JSON.parse(localStorage.getItem('auth'));
//     let itens = await fetch(`${URL_API}/convenios/all`,{
//         headers:{
//             "Accept": "application/json",
//             "Content-Type": "application/json",
//             "X-Auth-Token":auth.token
//         },
//         method:'get'
//     })
//     .then(res=>res.json())
//     .then((resJson)=>{
//         return resJson;
//     }).catch(err=>{
//         alert("Houveram erros na execução");
//         console.log(err);
//         return {};
//     });

//     return itens;
// }
