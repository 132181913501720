import React from "react";
import {useState,useEffect} from 'react';
import { useSelector,useDispatch } from 'react-redux';

export default function UiItensAvaliacao(props){

    const dispatch = useDispatch();

    const [procedimento, setProcedimento]=useState(props.procedimento?props.procedimento[0]:{});
    const [avaliacao, setAvaliacao]=useState(props.avaliacao?props.avaliacao[0]:{});

    useEffect(()=>{
        setAvaliacao(props.avaliacao?props.avaliacao[0]:{});
    },[props.avaliacao]);

    function excluirProcedimento(avaliacao, produto_id){
        let novaListaProcedimentos=[],total=0;
        avaliacao.procedimentos.map(e=>{
            if(e.produto_id!=produto_id)
                novaListaProcedimentos.push(e);
            total+=parseFloat(e.valor);
        });

        avaliacao.procedimentos=novaListaProcedimentos;
        avaliacao.total=total;
        
        dispatch({type:'FETCH_NOVA_AVALIACAO', payload:avaliacao});
        dispatch({type:'FETCH_ITENS_NOVA_AVALIACAO', payload:avaliacao.procedimentos});
    }

    return(
        <div className="lista-avalia">
            <div className="itens-lista-avalia">
                <div className="itens-lista">
                    <span>{procedimento.produto_nome}</span>
                </div>
                <div className="itens-lista">
                    <span>Dente/Região: {procedimento.dente_nome}</span>
                    <span>Faces: {procedimento.faces}</span>
                </div>
            </div>
            <div onClick={()=>{excluirProcedimento(avaliacao,procedimento.produto_id)}} className="icons-itens-lista">
                <i className="close material-icons icones right">close</i>                                                            
            </div>
        </div>
    );
}