import React,{useState, useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux'

import {filtroData} from '../../functions/Filtros/filtros'

export default function UiCardItemRepasse(props){

    const [autorizado, setAutorizado] = useState(props.autorizado?props.autorizado[0]:null);
    const [data, setData] = useState();

    useEffect(()=>{
        let dataFormat = filtroData(autorizado?autorizado.data_evolucao:null);
        setData(dataFormat)
    },[])

    return(
        <div>
            <div className="card row row-repass modal-trigger" href="#modalDetalheRepasse">
                <div style={{padding:"10px 0px 0px 10px"}}>
                    <span>PACIENTE: {autorizado?autorizado.paciente:null}</span><br/>
                    <span>PROCEDIMENTO: {autorizado?autorizado.titulo:null}</span><br/>
                    <span>DATA: {autorizado?data:null }</span>
                </div>
                <div className="card-comissao-total" style={{marginBottom:"15px"}}>
                    <span className="valor-comissao right blueBack">R$ {autorizado?autorizado.total:null}</span>
                    <span className="valor-comissao right yelouBack">R$ {autorizado?autorizado.comissao:null}</span>
                    <span className="valor-comissao right redBack">R$ {autorizado?autorizado.sacado:null}</span>
                    <span className="valor-comissao right greenBack">R$ {autorizado?autorizado.disponivel:null}</span>
                </div>
            </div>
        </div>
    );
}