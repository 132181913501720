import React from 'react';
import { useState,useEffect } from 'react';
import { useSelector,useDispatch } from 'react-redux';

import UiItensAvaliacao from "./ItensAvaliacao";
import UiHeaderTitleModal from "./HeaderTitleModal";
import UiHeaderSimple from './HeaderSimple.js';

import {URL_API} from '../../constantes/constantes';

export default function UiModalAdicionarTratamento(props){

    let reduxAvaliacao = useSelector(state=>state.prontuario.nova_avaliacao);
    let reduxProcedimentos = useSelector(state=>state.prontuario.procedimentos_nova_avaliacao);
    let fecharTela=null;

    const [avaliacao, setAvaliacao] = useState({});
    const [procedimentos, setProcedimentos] = useState([]);
    const [total, setTotal] = useState(0);
    // loading
    const [loadingArr, setLoadingArr] = useState([]);

    useEffect(()=>{
        let total=0;
        if(Object.keys(reduxAvaliacao).length>0){
            reduxAvaliacao.procedimentos.map(e=>{
                total+=parseFloat(e.valor);
            });
        }
        
        setTotal(total);
        setAvaliacao(Object.keys(reduxAvaliacao).length>0?reduxAvaliacao:{});
    },[reduxAvaliacao]);

    useEffect(()=>{
        let total=0;
        reduxProcedimentos.map(e=>{
            total+=parseFloat(e.valor);
        });

        setTotal(total);
        setProcedimentos(reduxProcedimentos);
    },[reduxProcedimentos]);

    function incluirLoading(id){
        let arr = loadingArr;
        arr.push(id);
    }

    function excluirLoading(id){
        let arr = loadingArr.filter(e=>e!=id);

        setLoadingArr(arr);
    }

    async function salvarAvaliacao(avaliacao,procedimentos){
        let total = 0;

        let procedimentoUpload=[];
        procedimentos.map(e=>{
            procedimentoUpload.push({
                valor: e.valor, 
                faces: e.faces, 
                dente_id: e.dente_id, 
                produto_id: e.produto_id, 
                convenio_id: avaliacao.convenio_id, 
                pessoa_id: avaliacao.pessoa_id
                // pessoa_id: 80
                // pessoa_id: 216
            });

            total+=parseFloat(e.valor);
        });
        let params = {
            ...avaliacao,
            total:total,
            // pessoa_id:80,
            procedimentos:procedimentoUpload
        };

        let auth = JSON.parse(localStorage.getItem('auth'));

        incluirLoading('salvandoAvaliacao');
        let salvou = await fetch(`${URL_API}/tratamentos/salvar-odonto`,{
            headers:{
                "Accept": "application/json",
                "Content-Type": "application/json",
                "X-Auth-Token":auth.token
            },
            method:'post',
            body:JSON.stringify({...params})
        })
        .then(res=>res.json())
        .then((resJson)=>{
            if(resJson.status=='ok'){
                alert("Avaliação salva com sucesso");
                return true;
            }else{
                alert(resJson.mensagem);
                return false;
            }
        }).catch(err=>{
            alert("Houve erros na execução");
            console.log(err);
            return {};
        });

        excluirLoading('salvandoAvaliacao');

        if(salvou){
            salvoutratamento();
        }
    }

    function salvoutratamento(){
        if(fecharTela)
            fecharTela.click();
        avaliacao.concluirAvaliacao();
    }

    return (
        <div>
            <div id="modalAddTratamento" className="modal bottom-sheet">
                <UiHeaderSimple/>
                <div className="modal-content">
                    <UiHeaderTitleModal callback={avaliacao?avaliacao.clearItensAvaliacao:()=>{}} title="Itens de Nova Avaliação" />
                    <div> 
                        <ul>
                            {procedimentos?procedimentos.map((e,index)=>{
                                return (
                                    <div key={index}>
                                        <li><UiItensAvaliacao avaliacao={[avaliacao]} procedimento={[e]}/></li>
                                        <li className="divider" tabIndex="-1"></li>
                                    </div>
                                )
                            }):null}
                        </ul>
                    </div>
                    <span>
                        Total: R$ {total}
                    </span>
                </div>
                <div className="modal-footer footer-itens">
                    <a ref={el => fecharTela = el} onClick={avaliacao?avaliacao.clearItensAvaliacao:()=>{}} className="modal-close waves-effect waves-green btn-flat left">Voltar</a>{/* href="#"  */}
                    <a className="btn-floating btn-small waves-effect waves-light btn-check-inner-info modal-trigger" href="#modalSalvartratamento"><i className="material-icons check-info">check</i></a>
                </div>

                <div id="modalSalvartratamento" className="modal">
                    <div className="modal-content">
                    <h4>Smartjob informa</h4>
                    <p>Deseja salvar esta avaliação?</p>
                    </div>
                    <div className="modal-footer">
                        <a href="#" className="modal-close waves-effect waves-green btn-flat">Não</a>
                        <a onClick={()=>{salvarAvaliacao(avaliacao,procedimentos)}} className="modal-close waves-effect waves-green btn-flat">Sim</a>{/* href="#"  */}
                    </div>
                </div>
            </div>
        </div>
    );
}