// export const filtroHome =(agendamentos)=>{
//     //console.log(agendamentos);
//     let  planos = [], especialidade=[], conv_hapvida = [], conv_particular = [];
    
//     agendamentos.map((e)=>{
//         let resConvHap = planos.filter((eh)=>eh.convenio_id === e.conveni_id);
//         if(resConvHap.length === 0){
//             planos.push({convenio:e.convenio,convenio_id:e.convenio_id})
//         }
//         console.log("planos",planos);
//     });    
// agendamentos.map((e)=>{
//     let resConvParticular = cv_particular.filter((ep)=>{  
// }
//     console.log(resConvParticular);
// });
//     return{ 
//         planos:planos,
//     }
// }


export const filtroHome = (agendamentos)=>{
    let planos = [], especialidades = [];
    agendamentos.map((e)=>{
        if(!planos[e.convenio_id]){
            planos[e.convenio_id]=[];
            planos[e.convenio_id].push(e);
        }else{
            planos[e.convenio_id].push(e);
        }

         if(!especialidades[e.sub_grupo_id]){
             especialidades[e.sub_grupo_id]=[];
             especialidades[e.sub_grupo_id].push(e);
         }else{
             especialidades[e.sub_grupo_id].push(e);
         }
    });
     return{
         planos:planos,
         especialidades:especialidades,
     }
}