import React from 'react';
import { useState,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {filtroData} from '../../functions/Filtros/filtros';

export default function UiCardAvaliacao(props){

    let [avaliacao,setAvaliacao] = useState(props.avaliacao?props.avaliacao[0]:null);
    let [dataFormatada, setDataFormatada] = useState(avaliacao.data_cadastro);

    function handlerAvaliacao(){
        props.callback();
    }

    useEffect(()=>{
        let DataF = filtroData(avaliacao.data_cadastro);
        setDataFormatada(DataF)
    });

    const infoStyle={
        qtdeStyle:{
            border:'1px solid rgb(132, 197, 167)',
            // backgroundColor:'rgb(42, 128, 192)',
            // color:'#fff',
            color:'#000',
            borderRadius:'30px',
            padding:'0px 5px',  
            marginRight:'10px'  
        },
        flexStyle:{
            display:'flex',
            alignItems:'center',
            marginBottom:'4px',
        }
    }

    return (
        <div>
            <div className="card card-avalia modal-trigger" onClick={handlerAvaliacao} href="#modalDetalheAvalia">
                <div className="card-content content-avalia">
                    <div className="info-avalia">
                        <div style={infoStyle.flexStyle}>
                            <span data-position="bottom" data-tooltip="I am a tooltip"  style={infoStyle.qtdeStyle}>{avaliacao.qtde_procedimentos} </span>   
                            <span> {avaliacao.id} </span><br/>
                        </div>
                        <span> {avaliacao.profissional}</span><br/>
                        <span>{avaliacao.convenio} </span>
                        <span> {dataFormatada}</span>
                    </div>
                    <div className="icons-card-avalia">
                        <i className="material-icons icones right">close</i>                                                            
                        <i className="material-icons icones right">create</i>
                    </div>
                </div>
                <div className="card-reveal">
                    <span className="card-title grey-text text-darken-4">Card Title<i className="material-icons right">close</i></span>
                    <p>Here is some more information about this product that is only revealed once clicked on.</p>
                </div>
            </div>
        </div>
    );
}