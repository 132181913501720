import React from 'react';

import '../../../css/checkin.css';
import UiHeader from "../../../componentes/ui/Header";

import {useEffect} from 'react';

//import { Header, Logo, Title,} from './style.js';

export default function ScreensAutorizadoCheckin(){

    useEffect(()=>{

        console.log("carregado");
        
    },[]);


    return(
        
        <div className="containe">
            <header className="topo">
            
              <UiHeader/>

              {/*<nav className="nav">
                    <div className="nav-conteudo">
                        <img className="nav-brand left" src={ logowin_horizontal } alt="logo"></img>
                        <a href="#" className="infjob left">InfoJob</a>
                    </div>  
                </nav> /}

                <nav className="nav">
                    <div className="nav-wrapper">
                    <a href="#" data-target="slide-out" class="sidenav-trigger"><i class="material-icons">menu</i></a>
                    <img src={logowin_horizontal} className="nav-brand" alt="logo InfoJob"/>
                    <ul id="nav-mobile" className="right hide-on-med-and-down">
                        <li><a href="sass.html">Sass</a></li>
                        <li><a href="badges.html">Components</a></li>
                        <li><a href="collapsible.html">JavaScript</a></li>
                    </ul>
                    </div>
                </nav>

                    <ul id="slide-out" class="sidenav">
                        <li>
                            <div class="user-view">
                                <div class="background">
                                    <img src="images/office.jpg" />
                                </div>
                                <a href="#user"><img class="circle" src={ft_perfil} /></a>
                                <a href="#name"><span class="black-text name">John Doe</span></a>
                                <a href="#email"><span class="black-text email">jdandturk@gmail.com</span></a>
                            </div>
                        </li>
                        <li><a href="#!"><i class="material-icons">cloud</i>First Link With Icon</a></li>
                        <li><a href="#!">Second Link</a></li>
                        <li><div class="divider"></div></li>
                        <li><a class="subheader">Subheader</a></li>
                        <li><a class="waves-effect" href="#!">Third Link With Waves</a></li>
                    </ul>*/}

            </header>
            
             <div className="corpo">

                <div className="corpo-inner">
                    <div className="titulo">
                      <span>Alteração de Consultório</span>
                    </div>

                    <div className="opcoes">

                        <div className="info">
                            <span>John Doe</span> <br/>
                            <span>xxx.xxx.xxx-xx </span><br/>
                            <span>Parangaba</span><br/>
                            <span>consutótio atual</span>
                            
                            <div className="input-field input-consult col s12">
                                <select className="select">
                                    <option value="" disabled selected>Selecione o seu consultório</option>
                                    <option value="1">Consultório 1</option>
                                    <option value="2">Consultório 2</option>
                                    <option value="3">Consultório 3</option>
                                </select>
                                <label>Selecionar consultório</label>
                            </div>
                        </div>

                        <button className="btn waves-effect waves-light botao" type="submit" name="action">Submit
                            <i className="material-icons right">send</i>
                        </button>
                    </div>
                </div>
            </div> 
        </div>
    );
}