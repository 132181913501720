import { agendamentos } from "../../store/reducers/agendamentos";

export const cpf_cnpj_formato = ()=>{
    var ao_cpf=document.forms.form1.ao_cpf.value; 
    var cpfValido = /^(([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}))$/;     
    if (cpfValido.test(ao_cpf) == false)    { 
         
       ao_cpf = ao_cpf.replace( /\D/g , ""); //Remove tudo o que não é dígito
                
       if (ao_cpf.length==11){
          ao_cpf = ao_cpf.replace( /(\d{3})(\d)/ , "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
          ao_cpf = ao_cpf.replace( /(\d{3})(\d)/ , "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
          //de novo (para o segundo bloco de números)
          ao_cpf = ao_cpf.replace( /(\d{3})(\d{1,2})$/ , "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos
                    
          var valorValido = document.getElementById("ao_cpf").value = ao_cpf;
        }else{
          console.log("CPF invalido");
        }
            
    }
      return console.log (ao_cpf) ;
}

//formatação de data 
export const filtroData=(data)=>{
  if(data){
    let dataFormat = data;
    dataFormat = dataFormat.split(" "); //separando a data do horario 
    dataFormat = dataFormat[0]; //pegando apenas a data
    dataFormat = dataFormat.split("-").reverse().join("-"); //transformando formato de aaaa/mm/dd para dd/mm/aaaa
    return dataFormat;
  }else{
    return null
  }
};

//formatação de hora em hh:mm
export const filtroHora=(hora)=>{
  let horaFormat = hora;
  horaFormat = horaFormat.split(":");
  let hr = horaFormat[0];
  let min = horaFormat[1];
  horaFormat = (hr + ":" + min);
  return horaFormat;
};

//calcular idade paciente
export const filtroIdade=(data_nascimento)=>{
  if(data_nascimento){
      let data = new Date();
      let idade = data_nascimento;
      let data_atual = (`${data.getFullYear()}-${(data.getMonth()+1)<10?('0'+(data.getMonth()+1)):data.getMonth()+1}-${data.getDate()}`);
      
      data_atual =  data_atual.split("-").reverse();
      idade =  idade.split("-").reverse();

      let dia_atual = data_atual[0], dia_idade = idade[0];
      let mes_atual = data_atual[1], mes_idade = idade[1];
      
      data_atual = data_atual[2];
      idade = idade[2];

      //se hoje for menor que o dia aniversario, então paciente ainda não fez aniversario
      if(mes_atual == mes_idade && dia_atual < dia_idade){     
        idade = (data_atual - idade)-1
      }else{
        idade = data_atual - idade;                    
      }
      
      return idade
  }

}


export const filtroFoto=(imagem)=>{
  if(imagem){
    return imagem.toLowerCase();
  }else{
    return "/images/user.png"
  }
}

export const buscaPaciente=(txt,agendamento)=>{

}