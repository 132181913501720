import React, { useState, useEffect } from 'react';
import { URL_API } from '../../../constantes/constantes';
import { useSelector, useDispatch } from "react-redux";

import M from "materialize-css";


import { fetchRepassesProfissional, } from "../../../store/reducers/repasses";

import '../../../css/repasses.css';
import '../../../css/geral.css';

import UiHeader from "../../../componentes/ui/Header";
import UiBotaoMenu from "../../../componentes/ui/BotaoMenu";
import UiCardRepasses from "../../../componentes/ui/CardRepasses";
import UiInfoProfissional from '../../../componentes/ui/InfoProfissional';


export default function ScreensAutorizadoRepasses(){
    const reduxRepasses = useSelector(state=>state.repasses.repasses);

    const dispatch = useDispatch();

    const [repasses, setRepasses] = useState([]);
    const [totais, setTotais] = useState([]);
    let totaisArr = [];

    useEffect(()=>{
        
        async function loadRepasses(){   
            M.AutoInit();
            
            let repasses = await fetchRepassesProfissional(repasses)
            dispatch({type:'FETCH_REPASSES_COMISSOES', payload:repasses});
        }
        loadRepasses();
    },[]);
    

    useEffect(()=>{
        setRepasses(reduxRepasses);
    },[reduxRepasses]);
    

    useEffect(()=>{
        let totCom = 0, totLiq = 0, totGlo = 0;

        for(var i = 0; i < repasses.length; i++){
            totCom += parseFloat(repasses[i].comissao);
            totLiq += parseFloat(repasses[i].liquido);
            totGlo += parseFloat(repasses[i].glosas)
        }

        let _totCom = totCom.toLocaleString('pt-BR'), _totLiq = totLiq.toLocaleString('pt-BR'), _totGlo = totGlo.toLocaleString('pt-BR');
        totaisArr.push({_totCom:_totCom,_totLiq:_totLiq,_totGlo:_totGlo});
        setTotais(totaisArr);
        console.log(totaisArr, 'arr')

    },[repasses])

    return(
        <div>
            <UiHeader/>
            <UiBotaoMenu/>
            <div className="conteudo">

                <div className="top-repass">
                    <div className="top-repass-inner">
                        <div className="card-panel card-repass">
                            <div className="row row-repass">
                                <UiInfoProfissional/>   
                                <div className="card-comissao-total" style={{flexWrap:'wrap'}}>
                                    <div style={{marginBottom:"5px"}}>
                                        <span style={{ marginRight:'5px'}}>TOTAL:</span>
                                    </div>
                                    <div className="card-comissao-total" style={{marginTop:"0px"}}>
                                        <span className="valor-comissao right blueBack">R$ {totais[0]?totais[0]._totCom:null}</span> 
                                        <span className="valor-comissao right greenBack">R$ {totais[0]?totais[0]._totLiq:null}</span>
                                        <span className="valor-comissao right redBack">R$ {totais[0]?totais[0]._totGlo:null}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bottom-repass">
                    { repasses?repasses.map((e,index)=>{
                        return <UiCardRepasses key={index} repasse={[repasses]} repasse={[e]}/>
                        }):null
                    }
                </div>
            </div>
        </div>
    );
}

