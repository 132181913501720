import React,{useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import M from "materialize-css";
import '../../css/geral.css'

import UiInfoProfissional from '../ui/InfoProfissional';
import UiCardItemRepasse from "./CardItemRepasse";
import UiHeaderTitleModal from "./HeaderTitleModal";

import {fetchAutorizados} from '../../store/reducers/repasses';


export default function UiCardRepasses (props){

    const reduxAutorizados = useSelector(state=>state.repasses.autorizados);
    
    const dispatch = useDispatch();
    
    const [repasse,setRepasse] = useState(props.repasse?props.repasse[0]:null);
    const [totais,setTotais] = useState();
// console.log(repasse,"repasse")
    const [autorizados, setAutorizados] = useState([]);
    const [evolucoesAutoriados, setEvolucoesAutorizados] = useState([]);
console.log(repasse, "totais")

    // let _total = 0, _commissao = 0, _sacado = 0, disponivel = 0;
    
    useEffect(()=>{
        async function loadInfoRepasses(){
            M.AutoInit();
            
            let autorizados = await fetchAutorizados(autorizados)
            dispatch({type:'FETCH_REPASSE_AUTORIZADO', payload:autorizados});

            let evolucoes = autorizados.evolucoes;
            setEvolucoesAutorizados(evolucoes)
        }
        loadInfoRepasses();
    },[]);
    
    useEffect(()=>{
        setAutorizados(reduxAutorizados);
    },[reduxAutorizados]);
    
    useEffect(()=>{
        let AutorizadosObj = []
        let totalAutorizado = 0, comissaoAutorizada = 0, sacadoAutorizado = 0, disponivelAutorizado = 0;

        if(evolucoesAutoriados){
            for(var i = 0; i < evolucoesAutoriados.length; i++){
                totalAutorizado += parseFloat(evolucoesAutoriados[i].total);
                comissaoAutorizada += parseFloat(evolucoesAutoriados[i].comissao);
                sacadoAutorizado += parseFloat(evolucoesAutoriados[i].sacado);
                disponivelAutorizado += parseFloat(evolucoesAutoriados[i].disponivel);
            }

            let _totalAutorizado = totalAutorizado.toLocaleString('pt-BR'), _comissaoAutorizada = comissaoAutorizada.toLocaleString('pt-BR');
            let _sacadoAutorizado = sacadoAutorizado.toLocaleString('pt-BR'), _disponivelAutorizado = disponivelAutorizado.toLocaleString('pt-BR');

            AutorizadosObj.push({total:_totalAutorizado, comissao:_comissaoAutorizada, sacado:_sacadoAutorizado, disponivel:_disponivelAutorizado})
            setTotais(AutorizadosObj)

        }
    },[autorizados]);


    return(
            <div className="row row-comissao">
                <div className="col col-comissao s12 m5">
                    <div className="card-panel card-panel-comissao modal-trigger"  href="#modalItemRepasse">
                        <div>
                            <span>Comissão</span>
                            <span className="valor-comissao right blueBack">R$ {repasse?repasse.comissao:0}</span>
                        </div>
                        <div>
                            <span>Líquido</span>
                            <span className="valor-comissao right greenBack">R$ {repasse?repasse.liquido:0}</span>
                        </div>
                        <div>
                            <span>Glosas</span>
                            <span className="valor-comissao right redBack">R$ {repasse?repasse.glosas:null}</span>
                        </div>
                    </div>
                </div>

                <div id="modalItemRepasse" className="modal bottom-sheet">
                    <div className="modal-content"> 
                        <div className="row row-repass">
                            <div style={{display:'flex'}}>   
                                <UiInfoProfissional/>
                                <div className="modal-footer width-close">
                                    <a /* href="#" */ onClick={props.callback} className="modal-close waves-effect waves-green btn-flat"><i className="material-icons">close</i></a>
                                </div>
                            </div>
                            <div className="card-comissao-total" style={{display:'flex'}}>
                                <div style={{display:'flex', flexDirection:'column'}}>
                                    <span>Total </span>
                                    <span className="valor-comissao right blueBack">R$ {totais?totais[0].total:null} </span>
                                </div>      
                                <div style={{display:'flex', flexDirection:'column'}}>
                                    <span>Comissão </span>
                                    <span className="valor-comissao right yelouBack">R$ {totais?totais[0].comissao:null}</span>
                                </div>      
                                <div style={{display:'flex', flexDirection:'column'}}>
                                    <span>Sacado </span>
                                    <span className="valor-comissao right redBack">R$ {totais?totais[0].sacado:null}</span>                                        
                                </div>    
                                <div style={{display:'flex', flexDirection:'column'}}>
                                    <span>Disponível </span>
                                    <span className="valor-comissao right greenBack">R$ {totais?totais[0].disponivel:null}</span>                                      
                                </div>     
                            </div>
                        </div>
                    
                        <div className="row" style={{marginTop:"15px"}}>
                            <div className="col s12" style={{marginBottom:"15px"}}>
                                <ul className="tabs">
                                    <li className="tab col s3"><a href="#autorizado" className="tooltipped active" data-position="bottom" data-tooltip="autorizado" >autorizado</a></li>
                                    <li className="tab col s3"><a href="#aguardando" className="tooltipped" data-position="bottom" data-tooltip="aguardando">aguardando</a></li>
                                    <li className="tab col s3"><a href="#antecipaveis" className="tooltipped" data-position="bottom" data-tooltip="antecipaveis">antecipaveis</a></li> 
                                    <li className="tab col s3"><a href="#glosas" className="tooltipped" data-position="bottom" data-tooltip="glosas">glosas</a></li> 
                                </ul>
                            </div>
                            <div id="autorizado" className="col s12">
                                {
                                    evolucoesAutoriados?evolucoesAutoriados.map((e,index)=>{
                                        return <UiCardItemRepasse key={index} autorizado={[autorizados]} autorizado={[e]}/> 
                                    }):null
                                }
                            </div>
                            <div id="aguardando" className="col s12"><UiCardItemRepasse/></div>
                            <div id="antecipaveis" className="col s12"><UiCardItemRepasse/></div>
                            <div id="glosas" className="col s12"><UiCardItemRepasse/></div>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <a href="#" className="modal-close waves-effect waves-green btn-flat">fechar</a>
                    </div>
                </div>

                <div id="modalDetalheRepasse" className="modal bottom-sheet" style={{height:"100%"}}>
                <div className="modal-content">

                    <UiInfoProfissional/>
                    <div>
                        <div>
                            <span>ID - Repasse - Convênio</span><br/>
                            <span>Venda/Guia</span><br/>
                            <span>Paciente</span><br/>
                            <span>Procedimento</span><br/>
                            <span>Data - Evolução</span><br/>
                        </div>
                        <div className="card-comissao-total" style={{marginBottom:"15px"}}>
                            <span className="valor-comissao right blueBack">R$ xx,xx</span>
                            <span className="valor-comissao right greenBack">R$ xx,xx</span>
                            <span className="valor-comissao right redBack">R$ xx,xx</span>
                        </div>
                    </div>
                </div>

                <div className="modal-footer">
                    <a href="#" className="modal-close waves-effect waves-green btn-flat">voltar</a>
                </div>
            </div>

            </div>
    );
}