import React from 'react';
import ReactLoading from 'react-loading';
 
export default function UiLoading(props){
    return (
        <div style={{position:"fixed",display:'flex',justifyContent:'center',top:'45%',left:0,right:0,zIndex:1000}}>
            <div style={{height:100,width:100,background:'white',padding:10,borderRadius:10}}>
                <ReactLoading type={props.type} color={props.color} height={50} width={70} />
                <span style={{color:props.color}}><b>Carregando</b></span>
            </div>
        </div>
    )
}