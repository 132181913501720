import React from "react";
import { useState, useEffect } from 'react';

import UiItensAvaliacao from "../ui/ItensAvaliacao";

import  '../../css/ComponentCss/Infopaciente.css';

import { Button } from 'react-materialize';

import ft_perfil from "../../img/ft_perfil.png";

export default function UiInfoPaciente (props){

    const [abrirInformacoes, setAbrirInformacoes] = useState(true);
    const [paciente, setPaciente] = useState({});

    const tiposAtendimento = ["avaliação", "atendimento", "retorno médico", "avaliação orto", "manutenção orto"];
    const statusAtendimento = ["marcado", "confirmado", "agenda excluída", "pendência recebimento", "na fila para atendimento", "sala do profissional", "término de atendimento", "não atendido", "ausente", "ocorrência"];

    // console.log(props);

    useEffect(()=>{
        setPaciente(props.paciente?props.paciente[0]:{});
    },[props.paciente]);
  
    const moreInformacao=()=>{
        setAbrirInformacoes(false)
        if(abrirInformacoes === false){
            setAbrirInformacoes(true)
        }
        else{
            setAbrirInformacoes(false)
        }
    }

    return(
        <div> 
            <div className="top-modal">
                    
                <div className="doct-info" >
                    <div><img src={ft_perfil} className="foto-perfil" alt='imagen de perfil'/></div>
                                    
                    <div className="span-info">
                        <span>{paciente?paciente.razao_social:null}</span><br/>
                        <span>{paciente?paciente.documento:null}</span><br/>
                        <div hidden={abrirInformacoes}>
                            <span>Número carteira: {paciente?paciente.numero_carteira:null}</span><br/>
                            {/* <span>Convênio: {paciente?paciente.convenio:null}</span><br/> */}
                            <span>Empresa: {paciente?paciente.empresa:null}</span><br/>
                            <span>Especialidade: {paciente?paciente.nome_abreviado_sb:null}</span><br/>
                            <span>Tipo de atendimento: {paciente?tiposAtendimento[(paciente.tipo-1)]:null}</span><br/>
                            <span>Status: {paciente?statusAtendimento[(paciente.status-1)]:null}</span><br/>
                        </div>
                    </div>
                </div>
                            
                <div className="botao-detail">
                    <Button className="more-btn" flat waves="light" onClick={()=>{moreInformacao()}}>
                        <i className="material-icons more-info-btn">keyboard_arrow_down</i>
                    </Button>
                </div>
            </div>
        </div>
    );
}