import {URL_API, URL_SITE} from '../../../constantes/constantes';
import {removerCredenciaisDeLogin} from '../../../functions/auth/authLogin';
import { sync } from 'glob';

const types={
    FETCH_AGENDAMENTOS_DIA:"FETCH_AGENDAMENTOS_DIA",
    FETCH_DETALHE_AGENDAMENTO:"FETCH_DETALHE_AGENDAMENTO",
    FETCH_AGENDAMENTOS_CLINICOS:"FETCH_AGENDAMENTOS_CLINICOS",
    FETCH_AGENDAMENTOS_MES:'FETCH_AGENDAMENTOS_MES',
    FETCH_TROCA_PROFISSIONAL:"FETCH_TROCA_PROFISSIONAL"
};

const INITIAL_STATE={
    agendamentos:[],
    detalhes_agendamento:{},
    allClinicos:[],
    agendamentoMes:[],
    troca_profissional:[]
};

export function agendamentos(state = INITIAL_STATE, action){
    switch(action.type){
        case types.FETCH_AGENDAMENTOS_DIA:
            return  {...state, agendamentos:action.payload};
        case types.FETCH_DETALHE_AGENDAMENTO:
            return {...state, detalhes_agendamento:action.payload};
        case types.FETCH_AGENDAMENTOS_CLINICOS:
            return{...state, allClinicos:action.payload};
        case types.FETCH_AGENDAMENTOS_MES:
            return{...state, agendamentoMes:action.payload};
        case types.FETCH_TROCA_PROFISSIONAL:
            return{...state, troca_profissional:action.payload};
        default:
            return state;
    }
}

// retornar agendamentos do dia
export const fetchAgendamentosDoDia=async (data_agendamento, especialidade)=>{
    let auth=JSON.parse(localStorage.getItem('auth'));
    let hoje=new Date();
    let amanha=new Date();
    amanha.setDate(amanha.getDate()+1);
    // O QUE DEVE SER USADO EM PRODUÇÃO COM ALTERAÇÕES
    // let pessoa_id=auth.pessoa_id;

    // AMBIENTE DE TESTES 
    //celina
    // let pessoa_id=211;
    // Amanda maria
    // let pessoa_id = 216;
    // luciana waki
    // let pessoa_id = 80; 
    // suzana orto
    // let pessoa_id = 233;
    //TALITA FELICIANo
    let pessoa_id= 430
    //"GERALDO MAJELA"
    // let pessoa_id = 417


    let clinicas = [];
    JSON.parse(localStorage.getItem('empresas')).map(e=>{
        if(e.id!=4&&e.id!=0){
            clinicas.push(e.id);
        }
    });

    let agendamentos = await fetch(`${URL_API}/smartjob-beta/retornar-agendamentos-do-profissional?pessoa_id=${pessoa_id}&dataIni=${data_agendamento}&clinicas=${JSON.stringify(clinicas)}&especialidade=${especialidade}`,{
        headers:{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "X-Auth-Token":auth.token
        },
        method:'get'
    })
    .then(res=>res.json())
    .then((resJson)=>{
        if(resJson.mensagem){
            removerCredenciaisDeLogin();
            window.location.replace(`${URL_SITE}/user/login`);
        }else{
            if(resJson.err){
                alert(resJson.message);
                return [];
            }else{
                return resJson.results;
            }
        }
    }).catch(err=>{
        alert("erros na execução");
        console.log(err);
        return [];
    });

    return agendamentos;
}

//retorna todos os agendamentos clinocos gerais do dia de todos os profissionais
export const fetchAgendamentosClinicos=async (data_agendamento)=>{
    let auth=JSON.parse(localStorage.getItem('auth'));
    let hoje=new Date();
    let amanha=new Date();
    amanha.setDate(amanha.getDate()+1);

    let clinicas = JSON.parse(localStorage.getItem('empresa'))
    let empresa = clinicas.id

    let allClinicos = await fetch(`${URL_API}/smartjob-beta/retornar-agendamentos-clinicos?dataIni=${data_agendamento}&empresa=${empresa}`,{
        headers:{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "X-Auth-Token":auth.token
        },
        method:'get'
    })
    .then(res=>res.json())
    .then((resJson)=>{
        if(resJson.mensagem){
            removerCredenciaisDeLogin();
            window.location.replace(`${URL_SITE}/user/login`);
        }else{
            if(resJson.err){
                alert(resJson.message);
                return [];
            }else{
                return resJson.results;
            }
        }
    }).catch(err=>{
        alert("Houveram erros na execução");
        console.log(err);
        return [];
    });

    return allClinicos;
}

//faz a troca d@ paciente de medic@ pr@ outr@
export const fetchTrocaProfissional = async(agendamento)=>{
    let auth=JSON.parse(localStorage.getItem('auth'));

    let params={
        pessoa_id:agendamento.pessoa_id,
        participante_id:agendamento.participante_id,
        novapessoa_id:auth.pessoa_id,
        agendamento_id: agendamento.agendamento_id,
    }

    let troca_profissional = await fetch(`${URL_API}/smartjob-beta/alterar-profissional-e-guia`,{
        headers:{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "X-Auth-Token":auth.token
        },
        method:'post',
        body:JSON.stringify({...params})
    })
    .then(res=>res.json())
    .then((resJson)=>{
        if(resJson.err){
            alert(resJson.message);
        }
    }).catch(err=>{
        alert("erros na execução");
        console.log(err);
    });
    return troca_profissional;
}


//retorna todos os agendamentos(terminod de atendimento) de certo mes de 1 profissional
export const fetchAgendamentosMes = async (mes)=>{

    let data = new Date();
    mes?mes=mes:mes=(data.getMonth()+1)

    let auth=JSON.parse(localStorage.getItem('auth'));

    let clinicas = JSON.parse(localStorage.getItem('empresa'))
    let empresa = clinicas.id

    let ano = data.getFullYear();
    // O QUE DEVE SER USADO EM PRODUÇÃO COM ALTERAÇÕES
    let pessoa_id=auth.pessoa_id;    

    // AMBIENTE DE TESTES 
    // let pessoa_id=211;

    let agendamentoMes = await fetch(`${URL_API}/smartjob-beta/retornar-agendamentos-finalizados-mes?pessoa_id=${pessoa_id}&empresa=${empresa}&mes=${mes}&ano=${ano}`,{
        headers:{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "X-Auth-Token":auth.token
        },
        method:'get'
    })
    .then(res=>res.json())
    .then((resJson)=>{
        if(resJson.mensagem){
            removerCredenciaisDeLogin();
            window.location.replace(`${URL_SITE}/user/login`);
        }else{
            if(resJson.err){
                alert(resJson.message);
                return [];
            }else{
                return resJson.results;
            }
        }
    }).catch(err=>{
        alert("Houveram erros na execução");
        console.log(err);
        return [];
    });

    return agendamentoMes;
}