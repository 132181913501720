import React from "react";

import { useState } from 'react';
import {fetchTratamentos} from '../../store/reducers/prontuario';
import { useSelector,useDispatch } from 'react-redux';

import M from "materialize-css";
import { Icon } from 'react-materialize';
import "../../css/ComponentCss/CardsTratamentoAuth.css"


export default function UiCardTratamentoAutorizado(props) {
    const dispatch = useDispatch(); 
    const [tratamento,setTratamento]=useState(props.tratamento?props.tratamento[0]:{});
    const [source, setSource] = useState(""); //adicionado
    const fileInput = React.createRef();

    // addEvolucao(tratamento,props.agendamento_id,props.participante_id)

    function upload(e){
        if(!e.target || !e.target.files || e.target.files.length <= 0){
            alert('no files');
            setSource('');
            return;

        }else{
            //visualização da imagem em tela
            const filePicker = e.target.files[0];
            const newUrl = URL.createObjectURL(filePicker);

            const formData = new FormData();
            formData.append('foto', filePicker);
    
            if(filePicker.size > 10485760){
                alert("Image is too big (max 10MB)");
                return;
            }

            setSource(newUrl);
        }
    }   

    function handleSubmit(event){
        event.preventDefault();

        let foto =  event.target.file;
    
        let form = new FormData();
        form.append('foto', fileInput.current.files[0]);

        props.callbackEnviaFoto(props.agendamento_id, form).then(()=>{
            setSource('')
        });
    }

    return(
        <div className="card">
            <div className="content-trata">
                <div className="card-content">
                    <div className="check-btn">
                        <a onClick={()=>{if(props.callback)props.callback(tratamento)}} className="btn-floating btn-small waves-effect waves-light btn-check-inner modal-trigger" href="#confirmar-conclusao-procedimento"><i className="material-icons check" >check</i></a>
                    </div>
                    <div className="card-info card-trata">
                        <span>{tratamento.titulo?tratamento.titulo:null}</span>
                        <span>DENTE/REGIÃO: {tratamento.dente_regiao?tratamento.dente_regiao:null} - FACE: {tratamento.face_dente?tratamento.face_dente:null}</span>
                        {/* <span>REAL.:{tratamento.realizado} - AUT.:{tratamento.autorizado}</span> */}
                    </div>
                </div>

                {
                    tratamento.produto_id === 1113?
                        <div className="envia-foto-tratamento">
                            <form onSubmit={handleSubmit}>
                                {source && 
                                    <div display="flex" justifycontent="center" border={1}>
                                        <img id="img-source" src={source} alt={"snap"}></img>
                                    </div>
                                }
                                <input
                                    accept="image/*"
                                    id="icon-button-file"
                                    type="file"
                                    capture="environment"
                                    ref = {fileInput}
                                    onChange={(e) => upload(e)}
                                />
                                <label htmlFor="icon-button-file">
                                    <a aria-label="upload image" component="span" className="btn-small waves-effect button-photo"> {/* btn-flat */}
                                        <i className="material-icons">photo_camera</i>
                                    </a>
                                </label>
                                {/* <input type="submit" value="Enviar" /> */}
                                <button className="btn-small waves-effect waves-light button-photo-send" type="submit" value="Enviar">Enviar</button>
                                {/* <a className="btn-small waves-effect waves-light button-photo-send" type="submit" value="Enviar">Enviar</a> */}
                            </form>
                        </div>
                    :null
                }
            </div>
        </div>
    );
}