import React,{useState,useEffect} from 'react';

import {TextInput,Button} from 'react-materialize';



import '../../../css/login.css'
import logowin from "../../../img/logowin.png";

import {URL_API, URL_SITE} from '../../../constantes/constantes';
import M from "materialize-css";

export default function ScreensAuthLogin(){

    useEffect(()=>{

        console.log("carregado");
        M.AutoInit();
    })

    const [username,setUsername] = useState("");
    const [password,setPassword] = useState("");
    const [empresaId,setEmpresaId] = useState("");
    const [empresas,setEmpresas] = useState([]);
    const [renderEmpresas,setRenderEmpresas] = useState(null);

    const carregarEmpresas = async () => {
        setEmpresaId(null);
        setEmpresas([]);

        let login = {
            username,
            password,
            empresaId
        };

        // console.log(login);
        if(login.username){
            // post
            let params = JSON.stringify(login);

            await fetch(`${URL_API}/auth/empresas`,{
                headers:{
                    'Accept':'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                method:'POST',
                body:JSON.stringify({username:login.username})
            })
            .then((res)=>res.json())
            .then(async (response)=>{

                if(!response.err){
                    setEmpresaId(response.data.empresa_id?response.data.empresa_id:"");
                    setEmpresas(response.data.empresas);
                }else{
                    alert(response.message);
                }

            }).catch(err=>{
                console.log(err);
            });
        }
    };

    const authLogar = async () =>{

        if(empresaId){
            let login = {
                username,
                password,
                empresa_id:empresaId
            };
    
            let encripty = window.btoa(JSON.stringify({u: window.btoa(login.username), p: window.btoa(login.password), e: window.btoa(login.empresa_id)}));
            let redirect = false;
    
            await fetch(`${URL_API}/auth/autenticacao`,{
                headers:{
                    'Accept':'application/json',
                    'Content-Type': 'application/json'
                },
                method:'POST',
                body:JSON.stringify({ token:encripty })
            })
            .then((res)=>res.json())
            .then(async (response)=>{
    
                if(response.status=='ok'){
                    
                    localStorage.setItem('auth', JSON.stringify(response.token));
                    localStorage.setItem('c', window.btoa(JSON.stringify(response.config)));
                    localStorage.setItem('menus', window.btoa(JSON.stringify(response.menus)));
                    localStorage.setItem('empresa', JSON.stringify(response.empresa));
                    localStorage.setItem('empresas', JSON.stringify(response.empresas));
                    redirect = true;
    
                }else{
                    alert(response.mensagem);
                }
    
            }).catch(err=>{
                console.log(err);
            });


            if(redirect)
                window.location.replace(`${URL_SITE}`);///user/agendamentos
        }else{
            alert("Empresa não localizada para este usuário");
        }

    };

    const handlerUsername=(e)=>{
        setUsername(e.target.value);
    }

    const handlerPassword=(e)=>{
        setPassword(e.target.value);
    }

    const handlerEmpresaId=(e)=>{
        setEmpresaId(e.target.value);
    }

    return (
        <div className="container">

            <div className="logo-cont">
                <img className="logo logo-login" src={logowin} alt="logo smart job"/>
                <span>SmartJob</span>
            </div>
            
            <div className="form">
                
                <div className="containerInput">
                    <TextInput onChange={handlerUsername} onBlur={()=>{carregarEmpresas()}} label="Login" />
                </div>

                <div className="containerInput">
                    <TextInput onChange={handlerPassword} type="password" label="Senha" />
                </div>

                <div className="input-field select-empresas">
                    <select value={empresaId || ''} onChange={handlerEmpresaId}>
                        <option value="" disabled>Selecione uma cliníca</option>
                        {
                            empresas.map(e=>{
                                return <option key={e.id} value={e.id}>{e.apelido}</option>
                            })
                        }
                    </select>
                    <label>Selecionar clinica</label>
                </div>

                <div className="containerCheck">
                    <label>
                        <input type="checkbox" className="filled-in login-check left" />
                        <span>continuar conectado</span>
                    </label>
                </div>

                {/* <SelectForm /> */}

                <div className="lineButtons">
                    <Button className="button waves-light" onClick={authLogar}waves="light">
                        Entrar
                    </Button>
                </div>
            </div>
            
        </div>
    
    );
}
