import React, { useState, useEffect} from 'react';
import smartjob_logomarca from '../../img/smartjob_logomarca.png';
import '../../css/ComponentCss/HeaderMenu.css'

import {logout} from '../../functions/auth/authLogin';
import {filtroFoto} from '../../functions/Filtros/filtros';
import UiModalAgendadosMes from './ModalAgendadosMes'

export default function UiHeader(props){

    const [searchInput, setSearchInput] = useState("");
    
    function handleChange(e){
        setSearchInput(e.target.value);
        handleSearch(searchInput)
    }   

    function handleSearch(searchInput){
        if(props.callback)
        props.callback(searchInput);
    }

    let storageProfissional = JSON.parse(localStorage.getItem('auth'));

    const [profissional, setProfisional] = useState({});
    const [ftPerfil, setFtPerfil] = useState();



    useEffect(()=>{
        setProfisional(storageProfissional);
    },[]);

    useEffect(()=>{
        let img = filtroFoto(profissional.path_foto);
        setFtPerfil(img)
    },[profissional.path_foto])


    return(
        <div>
            <nav className="nav">
                <div className="nav-wrapper">
                    <a href="#" data-target="slide-out" className="sidenav-trigger"><i className="material-icons">menu</i></a>
                    <img src={smartjob_logomarca} className="nav-brand" alt="logo SmartJob"/>
                    <div className='SearchPosi'>
                        <form className="InnerForm">
                            <div className="input-field inputPosit">
                                <input 
                                    placeholder="Buscar" 
                                    type="text" 
                                    className="inputy" 
                                    value={searchInput}
                                    onChange={handleChange}
                                />
                                <i className="material-icons" style={{marginBottom:'10px'}}>search</i>
                            </div>
                        </form>
                    </div>
                    <ul id="nav-mobile" className="right hide-on-med-and-down">
                        <li><a className="waves-effect" onClick={logout} style={{marginLeft:'10px'}}>Sair</a></li>
                    </ul>
                </div>
            </nav>

            <UiModalAgendadosMes/>
            <ul id="slide-out" className="sidenav">
                <li>
                    <div className="user-view">
                        <div className="background back-nav"></div>
                        <a href="#user"><img className="circle" alt="foto de perfil" src={`http://smartjob.bemestarcmo.com.br/api/public/${ftPerfil}`} /></a>
                        <a href="#name"><span className="black-text name">{profissional?profissional.nome:null}</span></a>
                        <a href="#cpf "><span className="black-text cpf">{profissional?profissional.cpf:null}</span></a>                          
                        <a href="#email"><span className="black-text email">{profissional.email?profissional.email:null}</span></a>
                    </div>
                </li>
                {/* <li><a href="#"><i className="material-icons">cloud</i>First Link With Icon</a></li> */}
                <li><a className=" modal-trigger"  href="#modalAgendadosMes">Atendidos no Mês</a></li>
                <li><div className="divider"></div></li>
                <li><a className="subheader">Subheader</a></li>
                {/* <li><a className="waves-effect" href="#">Third Link With Waves</a></li> */}
                <li><a className="waves-effect" onClick={logout}>Sair</a></li>
            </ul>
        </div>
    );
}