import {filtroHora} from '../Filtros/filtros';

export const filtroAgendamentos = (agendamentos) =>{
    let planos = [], especialidades = [], empresas = [], grupos = [], status = [], tipos=[];
    let planosArray=[], especialidadesArray=[], empresasArray=[], gruposArray=[], statusArray=[], tiposArray=[], turnoArray=[], filtroAgendamento=[], prioridadesArray = [], NaoPrioridArray=[],aux = [], total = 0;
    let tiposAtendimento = ["avaliação", "atendimento", "retorno médico", "avaliação orto", "manutenção orto"];
    let statusAtendimento = ["marcado", "confirmado", "agenda excluída", "pendência recebimento", "na fila para atendimento", "sala do profissional", "término de atendimento", "não atendido", "ausente", "ocorrência"];
    let statusAbreviado = ["M", "C", "E", "P", "F", "S", "T", "N", "A", "O"];

    agendamentos.map((e)=>{

        //separando agendamentos por turno
        let turno = filtroHora(e.inicio);

        if(turno < '12:00:00'){
            turno = 0;
            e['turno'] = 'MANHÃ';
        }
        else if(turno > '12:00:00' && turno < '19:00:00'){
            turno = 1;
            e['turno'] = 'TARDE';
        }

        let resConvenio = planos.filter((er)=>er.convenio_id === e.convenio_id);
        if(resConvenio.length===0){
            planos.push({convenio:e.convenio,convenio_id:e.convenio_id})
        }
        
        let resEspecialidade = especialidades.filter((es)=>es.sub_grupo_id === e.sub_grupo_id);
        if(resEspecialidade.length === 0){
            especialidades.push({especialidade:e.nome_abreviado_sb, sub_grupo_id:e.sub_grupo_id})
        }
        
        let resEmpresa = empresas.filter((em)=>em.empresa_id === e.empresa_id);
        if(resEmpresa.length === 0){
            empresas.push({empresa:e.empresa,empresa_id:e.empresa_id})
        }
        
        let resGrupo = grupos.filter((eg)=> eg.grupo_id === e.grupo_id);
        if(resGrupo.length === 0){
            grupos.push({grupo:e.grupo, grupo_id:e.grupo_id})
        }
        
        let resStato = status.filter((et)=> et.status === e.status);
        if(resStato.length === 0){
            status.push({status:e.status, nome: statusAtendimento[(e.status-1)], abreviacao:statusAbreviado[(e.status-1)]})
        }
        
        let resTipo = tipos.filter((ep)=> ep.tipo === e.tipo);
        if(resTipo.length === 0){
            tipos.push({tipo:e.tipo,nome:tiposAtendimento[(e.tipo-1)]})
        }
        
        if(!planosArray[e.convenio_id]){
            planosArray[e.convenio_id]=[];
            planosArray[e.convenio_id].push(e);
        }else{
            planosArray[e.convenio_id].push(e);
        }
        
        if(!especialidadesArray[e.sub_grupo_id]){
            especialidadesArray[e.sub_grupo_id]=[];
            especialidadesArray[e.sub_grupo_id].push(e);
        }else{
            especialidadesArray[e.sub_grupo_id].push(e);
        }
        
        if(!empresasArray[e.empresa_id]){
            empresasArray[e.empresa_id]=[];
            empresasArray[e.empresa_id].push(e);
        }else{
            empresasArray[e.empresa_id].push(e);
        }
        
        if(!gruposArray[e.grupo_id]){
            gruposArray[e.grupo_id]=[];
            gruposArray[e.grupo_id].push(e);
        }else{
            gruposArray[e.grupo_id].push(e);
        }
        
        if(!statusArray[e.status]){
            statusArray[e.status]=[];
            statusArray[e.status].push(e);
        }else{
            statusArray[e.status].push(e);
        }

        if(!turnoArray[turno]){
            turnoArray[turno]=[];
            turnoArray[turno].push(e);
        }else{
            turnoArray[turno].push(e);
        }

    /*INICIO filtro p não mostrar os pacientes atendidos e intercalar entre prioridade e não prioridade*/
        if(e.status!=7){
            aux.push(e);

            if(e.prioridade!= 0 || e.prioridade!= null){
                prioridadesArray.push(e);
            }else{
                NaoPrioridArray.push(e);
            }
            
        }   

        if(!tiposArray[e.tipo]){
            tiposArray[e.tipo]=[];
            tiposArray[e.tipo].push(e);
        }else{
            tiposArray[e.tipo].push(e);
        }

    });

    /*INICIO filtro p não mostrar os pacientes atendidos e intercalar entre prioridade e não prioridade*/
    if (prioridadesArray.length > NaoPrioridArray.length) {
        total = prioridadesArray.length;
    } else {
        total = NaoPrioridArray.length;
    }

    for (var i = 0; i < total; i++) {
        if (prioridadesArray[i]) {
            filtroAgendamento.push(prioridadesArray[i]);
        }
        if (NaoPrioridArray[i]) {
            filtroAgendamento.push(NaoPrioridArray[i]);
        }
    }
    /*FIM filtro p não mostrar os pacientes atendidos e intercalar entre prioridade e não prioridade*/
    
    return {
        filtros :{
            especialidades:especialidades, 
            planos:planos, 
            empresas:empresas, 
            grupos:grupos,
            status:status, 
            tipos:tipos,
            newAgendamento:filtroAgendamento
        },
        filtrosValores:{
            planosArray,
            especialidadesArray,
            empresasArray,
            gruposArray,
            statusArray,
            tiposArray,
            turnoArray,
            filtroAgendamento
        }
        
    }
}

export const agendamentosPorPlanos=(agendamentos) => {
    let planos=[] ;
    agendamentos.map((e)=>{
        if(!planos[e.convenio_id]){
            planos[e.convenio_id]=[];
            planos[e.convenio_id].push(e);
        }else{
            planos[e.convenio_id].push(e);
        }
    });
    return planos
}

export const todosClinicos=(clinicos)=>{
    let allClinicos = [], prioridadesArray=[], NaoPrioridArray=[], allClinicosGerais=[], total = 0;
    if(clinicos){
    clinicos.map((e)=>{
        if(e.status==5){
            allClinicos.push(e);

            allClinicos.sort(function(a,b) {
                if( a.status_cadastro &&  b.status_cadastro){/* ck_data_hora_inicio valor ultilizado*/
                    let x = a.status_cadastro.split(" ")[1];
                    let y = b.status_cadastro.split(" ")[1];
                    
                    return  x < y ? -1 : x < y ? 1 : 0;
                }
            });

            if(e.prioridade!=0){
                prioridadesArray.push(e);
            }else{
                NaoPrioridArray.push(e);
            }
        }
    })}

    if (prioridadesArray.length > NaoPrioridArray.length) {
        total = prioridadesArray.length;
    } else {
        total = NaoPrioridArray.length;
    }
    //intercala agendamentos prioritários e não prioritário
    for (var i = 0; i < total; i++) {
        if (prioridadesArray[i]) {
            allClinicosGerais.push(prioridadesArray[i]);
        }
        if (NaoPrioridArray[i]) {
            allClinicosGerais.push(NaoPrioridArray[i]);
        }
    }

    return {
        filtrosValores:{
            allClinicosGerais
        }
    };

}

/*
* INICIO Mudar cor de acordo com o status
*/
export const StatusCores=(agendamento)=>{
    let value = agendamento.status;
    if(value==1){
        return 'case1';
    }else if(value==2){
        return 'case2';
    }else if(value==3){
        return 'case3';
    }else if(value==4){
        return 'case4';
    }else if(value==5){
        return 'case5';
    }else if(value==6){
        return 'case6';
    }else if(value==7){
        return 'case7';
    }else if(value==8){
        return 'case8';
    }else if(value==8){
        return 'case9';
    }else if (value==9){
        return 'case9';
    }else if (value==10){
        return 'case10';
    }else{
        return 'badge-btn';
    }
}
/*
* FIM Mudar cor de acordo com o status
*/

/**INICIO muda cor bordas card */

export const bordeCardColor=(agendamento)=>{
    let value = agendamento.status;
    if (value == 7){
        return 'borderCase7';
    }
}
/**FIM muda cor bordas cards */
