import React from 'react';
import { useState,useEffect } from 'react';
import { useSelector,useDispatch } from 'react-redux';

import {URL_API} from '../../constantes/constantes';

import M from "materialize-css";
import { Button } from 'react-materialize';
/*import $ from 'jquery';*/

import '../../css/ComponentCss/Modalagendamento.css'

import ft_perfil from "../../img/ft_perfil.png";

import UiCardTratamentoNaoAutorizado from "./CardsTratamentoNaoAutorizado";
import UiCardTratamentoAutorizado from "./CardsTratamentoAutorizado";
import UiCardTratamentoEvolucao from "./CardsTratamentoEvolucao";
import UiCardAvaliacao from "./CardAvaliacao";
import UiInfoPaciente from "./InfoPaciente";
import UiHeaderTitleModal from "./HeaderTitleModal";
import UiModalDetalheAvaliacao from './ModalDetalheAvaliacao';
import UiModalLancarAvaliacao from './ModalLancarAvaliacao';
import UiModalAdicionarTratamento from './ModalAdicionarTratamento';
import UiHeaderSimple from './HeaderSimple.js';

import {fetchAvaliacoes, fetchTratamentos, fetchAddFoto} from '../../store/reducers/prontuario';

import UiLoading from '../../componentes/ui/Loading';

export default function UiModalAgendamentos (props){
    // integração com redux
    const dispatch = useDispatch();
    let reduxAgendamento = useSelector(state=>state.agendamentos.detalhes_agendamento);
    let reduxAvaliacoes = useSelector(state=>state.prontuario.avaliacoes);
    let reduxTratamentos = useSelector(state=>state.prontuario.tratamentos);
    
    // states
    const [mostrabtn, setMostraBtn] = useState(true);
    const [agendamento, setAgendamento] = useState({});
    const [avaliacoes, setAvaliacoes] = useState([]);
    const [tratamentos, setTratamentos] = useState({});
    const [avaliacao, setAvaliacao] = useState({});
    // loading
    const [loadingArr, setLoadingArr] = useState([]);

    // item selecionado
    const [procedimentoSelecionado, setProcedimentoSelecionado] = useState({});


    // useEffects
    useEffect(()=>{
        setAgendamento(reduxAgendamento);
        setAvaliacoes(reduxAvaliacoes);
        setTratamentos(reduxTratamentos);
    },[]);

    useEffect(()=>{
        setAgendamento(reduxAgendamento);

        async function loadAvaliacoes(participante_id,agendamento_id){
            incluirLoading('buscandoTratamentos');
            let [responseAvaliacoes, responseTratamentos] =  await Promise.all([
                                                            fetchAvaliacoes(participante_id),
                                                            fetchTratamentos(participante_id,agendamento_id)
                                                        ]);

            excluirLoading('buscandoTratamentos');

            dispatch({type:'FETCH_AVALIACOES', payload:responseAvaliacoes});
            dispatch({type:'FETCH_TRATAMENTOS', payload:responseTratamentos});
        }

        if(reduxAgendamento.participante_id){
            loadAvaliacoes(reduxAgendamento.participante_id,reduxAgendamento.agendamento_id);
        }
    },[reduxAgendamento]);


    useEffect(()=>{
        setAvaliacoes(reduxAvaliacoes);
    },[reduxAvaliacoes]);

    useEffect(()=>{
        setTratamentos(reduxTratamentos);

    },[reduxTratamentos]);


    const btnAvalia=()=>{
        setMostraBtn(false);
    }

    const btnAvaliaHide=()=>{
        setMostraBtn(true);
    }

    function handlerAvaliacao(avaliacao){
        setAvaliacao(avaliacao);
    }

    function handlerExcludeAvaliacao(){
        setAvaliacao({});
    }

    function handlerProcedimentoSelecionado(procedimento){
        setProcedimentoSelecionado(procedimento);
    }

    //enviando foto do procedimento
    async function enviaFoto(agendamento_id, file){

        incluirLoading('enviandoFoto');
        let responseFoto = await fetchAddFoto(agendamento_id, file);
        excluirLoading('enviandoFoto');

        dispatch({type:'FETCH_ADD_FOTO', payload:responseFoto});

        return responseFoto;
    }

    const centerStyle={
        display:'flex',
        justifyContent:'center',
    };


    async function addEvolucao(tratamento, agendamento_id, participante_id){
        let empresa = JSON.parse(localStorage.getItem('empresa'));
        let auth = JSON.parse(localStorage.getItem('auth'));
        let params = {
            agendamento_id: agendamento_id,
            data_evolucao: `${(new Date()).getFullYear()}-${((new Date()).getMonth()+1)}-${(new Date()).getDate()} ${(new Date()).getHours()}:${(new Date()).getSeconds()}`,
            descricao: tratamento.titulo+` FACE FOI FINALIZADA`,
            empresa_id: empresa.id,
            item_venda_id: tratamento.item_venda_id,
            participante_id: participante_id,
            // pessoa_id: auth.pessoa_id
            pessoa_id:  430

        };

        await fetch(`${URL_API}/prontuarios/add-evolucao`,{
            headers:{
                "Accept": "application/json",
                "Content-Type": "application/json",
                "X-Auth-Token":auth.token
            },
            method:'POST',
            body:JSON.stringify(params)
        })
        .then(res=>res.json())
        .then((resJson)=>{
            if(resJson.status=='ok'){
                alert("Procedimento concluído com sucesso");
                return {};
            }else{
                alert(resJson.mensagem);
                return {};
            }
        }).catch(err=>{
            alert("Houve erros na execução");
            console.log(err);
            return {};
        });

        let responseTratamentos = await fetchTratamentos(participante_id,agendamento_id);
        dispatch({type:'FETCH_TRATAMENTOS', payload:responseTratamentos});
    }

    function incluirLoading(id){
        let arr = loadingArr;
        arr.push(id);
    }

    function excluirLoading(id){
        let arr = loadingArr.filter(e=>e!=id);

        setLoadingArr(arr);
    }

    return(
        <div>   
            <div id="modalAgendamentos" className="modal bottom-sheet">
                { loadingArr.length>0 && <UiLoading type="bubbles" color="#1976de" />}
                <UiHeaderSimple/>
                <div className="modal-content">
                    <UiHeaderTitleModal title="Informações de agendamento" agenda={props.agenda} callback={props.callback}/>

                    <div className="flex-info">
                        <UiInfoPaciente paciente={[agendamento]}/> 
                        <div className="btn-adi">  
                            <Button  className="add-btn modal-trigger right" href="#modalLancarAval" flat waves="light" onClick={()=>{btnAvalia()}}>
                                <i className="medium material-icons add-info-btn">add_circle_outline</i>
                            </Button>                          
                        </div>
                    </div>

                    <div className="bottom-modal"> 
                        <div className="row">
                            <div className="col s12">
                                <ul className="tabs tab-switch">
                                    <li className="tab col tab-col-avalia s3"><a onClick={()=>{btnAvalia()}} href="#test1">Avaliação</a></li>
                                    <li className="tab col tab-col-trata s3"><a className="active" href="#test2" onClick={()=>btnAvaliaHide()}>Tratamentos</a></li>
                                </ul>
                            </div>

                            <div id="test1" className="col col-avalia s12"  style={{marginTop:'15px'}}>
                                {
                                    avaliacoes?
                                        avaliacoes.length>0?avaliacoes.map(e=>{
                                        return <UiCardAvaliacao key={e.id} callback={()=>{handlerAvaliacao(e)}} avaliacao={[e]}/>
                                        }):<span style={centerStyle} >Não há avaliações lançadas</span>
                                    :null
                                }
                            </div>

                            <div id="test2" className="col col-trata s12" style={{marginTop:'10px'}}> 
                                <h4 className="card-title center">Autorizados
                                    <a className="dropdown-trigger icones-a right" href="#" data-target="dropdown2"> 
                                        <i className="material-icons">sort</i>
                                    </a>
                                    <ul id="dropdown2" className="dropdown-content drops-info">
                                        <li><a href="#">falta</a></li>
                                        <li><a href="#">realizado</a></li>
                                        <li><a href="#">autorizado</a></li>
                                        <li className="divider" tabIndex="-1"></li>
                                        <li><a href="#">todos</a></li>
                                    </ul>
                                </h4>
                                {
                                    tratamentos.autorizados?
                                        tratamentos.autorizados.length>0?tratamentos.autorizados.map(e=>{
                                                                                                                        
                                            return <UiCardTratamentoAutorizado callback={handlerProcedimentoSelecionado} callbackEnviaFoto={enviaFoto} agendamento_id={agendamento.agendamento_id} participante_id={agendamento.participante_id}  tratamento={[e]} key={e.id}/>;
                                        }):<span style={centerStyle}>Não há tratamentos autorizados</span>
                                    :null
                                }

                                <h4 className="card-title center">Não-Autorizados</h4>
                                {
                                    tratamentos.nao_autorizados?
                                        tratamentos.nao_autorizados.length>0?tratamentos.nao_autorizados.map((e,index)=>{
                                            return <UiCardTratamentoNaoAutorizado tratamento={[e]} key={index}/>;
                                        }):<span style={centerStyle}>Não há tratamentos não-autorizados</span>
                                    :null
                                }

                                <h4 className="card-title center">Evoluções</h4>
                                {
                                    tratamentos.evolucoes?
                                        tratamentos.evolucoes.length>0?tratamentos.evolucoes.map((e)=>{
                                            return <UiCardTratamentoEvolucao tratamento={[e]} key={e.id}/>;
                                        }):<span className="center" style={centerStyle}>Não há evoluções</span>
                                    :null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <a href="#" className="modal-close waves-effect waves-green btn-flat">fechar</a>
                    </div>
                </div>
            </div>

            <div id="confirmar-conclusao-procedimento" className="modal">
                <div className="modal-content">
                <h4>Smartjob informa</h4>
                <p>Deseja concluir o procedimento?</p>
                </div>
                <div className="modal-footer">
                    <a href="#" className="modal-close waves-effect waves-green btn-flat">Não</a>
                    <a href="#" onClick={()=>{addEvolucao(procedimentoSelecionado,agendamento.agendamento_id,agendamento.participante_id)}} className="modal-close waves-effect waves-green btn-flat">Sim</a>
                </div>
            </div>

            <UiModalDetalheAvaliacao callbackClose={handlerExcludeAvaliacao} avaliacao={[avaliacao]} />

            <UiModalLancarAvaliacao />

            <UiModalAdicionarTratamento />
        </div>
    );
}
