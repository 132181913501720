import React from 'react';
import { useState,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';


import UiCardsTratamentoNaoAutorizado from "./CardsTratamentoNaoAutorizado";
import UiInfoPaciente from "./InfoPaciente";
import UiHeaderTitleModal from "./HeaderTitleModal";
import UiHeaderSimple from './HeaderSimple.js';

import {fetchItensAvaliacao} from '../../store/reducers/prontuario';
import UiLoading from '../../componentes/ui/Loading';

export default function UiModalDetalheAvaliacao(props){

    const dispatch = useDispatch();

    let reduxAgendamento = useSelector(state=>state.agendamentos.detalhes_agendamento);
    let reduxItensAvaliacao = useSelector(state=>state.prontuario);

    const [agendamento, setAgendamento] = useState({});
    const [itensAvaliacao, setItensAvaliacao] = useState([]);
    // loading
    const [loadingArr, setLoadingArr] = useState([]);

    useEffect(()=>{
        setAgendamento(reduxAgendamento);
    },[reduxAgendamento]);

    useEffect(()=>{
        async function loadItensAvaliacao(avaliacao_id){

            incluirLoading('buscandoItensAvaliacao');
            let itens = await fetchItensAvaliacao(avaliacao_id);
            excluirLoading('buscandoItensAvaliacao');

            setItensAvaliacao(itens.procedimentos);
        }

        if(props.avaliacao.length>0){
            if(Object.keys(props.avaliacao[0]).length !== 0 && props.avaliacao[0].constructor === Object){
                // console.log(props.avaliacao);
                loadItensAvaliacao(props.avaliacao[0].id);
            }
        }
    },[props.avaliacao]);
    
    function incluirLoading(id){
        let arr = loadingArr;
        arr.push(id);
    }

    function excluirLoading(id){
        let arr = loadingArr.filter(e=>e!=id);
        
        setLoadingArr(arr);
    }

    return(
        <div>
            <div id="modalDetalheAvalia" className="modal bottom-sheet">
                { loadingArr.length>0 && <UiLoading type="bubbles" color="#1976de" />}
                <UiHeaderSimple/>
                <div className="modal-content">
                    <UiHeaderTitleModal callback={()=>{props.callbackClose()}} title="Informações da Avaliação"/>
                    <UiInfoPaciente paciente={[agendamento]}/>
                    {
                        itensAvaliacao?itensAvaliacao.map((e,index)=>{
                            return <UiCardsTratamentoNaoAutorizado tratamento={[{
                                agendamento_id: e.agendamento_id,
                                autorizado: e.aprovado,
                                codigo_referencia: e.produto.codigo,
                                dente_id: e.dente_id,
                                dente_regiao: e.dente.codigo,
                                faces: e.faces,
                                forma_pagamento_id: null,
                                item_venda_id: e.item_venda_id,
                                pessoa_id: e.pessoa_id,
                                produto_id: e.produto.id,
                                realizado: e.evolucao,
                                status: e.status,
                                titulo: e.produto.titulo
                            }]} key={index}/>
                        }):<span>Não há itens para esta avaliação</span>
                    }
                </div>
                <div className="modal-footer">
                    <a href="#" onClick={props.callbackClose} className="modal-close waves-effect waves-green btn-flat">Voltar</a>
                </div>
            </div>
        </div>
    )
}