import React, {useEffect, useState}  from 'react';
import { useSelector, useDispatch } from 'react-redux';

import M from 'materialize-css';

import ft_perfil from '../../img/ft_perfil.png';

import {filtroFoto} from '../../functions/Filtros/filtros'

import {fetchInformacoesBasicas} from '../../store/reducers/profissional';

import UiLoading from '../../componentes/ui/Loading';

export default function UiInfoProfissional(props){
    
    const reduxProfisional=useSelector(state=>state.profissional.informacoes_basicas);
    const dispatch = useDispatch();

    const [ profissional, setProfissional ] = useState({});
    const [foto, setFoto] = useState();
    // loading
    const [loadingArr, setLoadingArr] = useState([]);

    useEffect(()=>{
        async function loadAgendamentos(){
            M.AutoInit();
            
            let auth=JSON.parse(localStorage.getItem('auth'));
            
            incluirLoading('carregandoProfissional');
            let [profissional] = await Promise.all([
                fetchInformacoesBasicas(auth.pessoa_id)
            ]);
            
            excluirLoading('carregandoProfissional');
            
            dispatch({type:'FETCH_INFORMACOES_BASICAS', payload:profissional});
        }
        loadAgendamentos();
    },[]);
    
    
    useEffect(()=>{
        setProfissional(reduxProfisional);
    },[reduxProfisional]);
    
    useEffect(()=>{
        if(!profissional){
            profissional.path_foto = null;
            let ft = filtroFoto(profissional.path_foto);
            setFoto(ft)
        }else{
            let ft = filtroFoto(profissional.path_foto);
            setFoto(ft)
        }
    },[profissional.path_foto])
    
    function incluirLoading(id){
        let arr = loadingArr;
        arr.push(id);
    }
    
    function excluirLoading(id){
        let arr = loadingArr.filter(e=>e!=id);
        
        setLoadingArr(arr);
    }

    return(
        <div>
            { loadingArr.length>0 && <UiLoading type="bubbles" color="#1976de" />}
            <div className="top-modal">
                <div className="doct-info">
                    <div><img className="foto-perfil" alt="foto de perfil" src={`http://smartjob.bemestarcmo.com.br/api/public/${foto}`}/></div>
                    <div className="info-repass">
                        <span>{profissional?profissional.apelido:null}</span>
                        <span>{profissional?profissional.cpf:null}</span>
                        <span>ID Profissional: {profissional?profissional.pessoa_id:null}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}