import React from 'react';
import { useState,useEffect,useRef } from 'react';
import { useSelector,useDispatch } from 'react-redux';

import UiHeaderTitleModal from "./HeaderTitleModal";
import UiHeaderSimple from './HeaderSimple.js';
import M from "materialize-css";
import ft_perfil from "../../img/ft_perfil.png";
import { Button } from 'react-materialize';
import Downshift from 'downshift';

import {fetchCovenios, fetchProcedimentos, fetchDentes} from '../../store/reducers/prontuario';
import UiLoading from '../../componentes/ui/Loading';

export default function UiModalLancarAvaliacao(){

    const dispatch = useDispatch();

    let reduxProcedimentosAvaliacao = useSelector(state=>state.prontuario.procedimentos_nova_avaliacao);
    let reduxAgendamento = useSelector(state=>state.agendamentos.detalhes_agendamento);
    let reduxDentes = useSelector(state=>state.prontuario.dentes);
    let reduxProcedimentos = useSelector(state=>state.prontuario.procedimentos);
    let reduxConvenios = useSelector(state=>state.prontuario.convenios);
    let storageProfissional= JSON.parse(localStorage.getItem('auth'));
    // loading
    const [loadingArr, setLoadingArr] = useState([]);

    let refButtonTratamento = null;
    let refButtonDente = null;
    let refButtonFechar = null;
    
    // states
    const [buttonTratamento, setButtonTratamento]=useState(null);
    const [buttonDente, setButtonDente]=useState(null);
    const [buttonFechar, setbuttonFechar]=useState(null);
    const [agendamento, setAgendamento] = useState({});
    const [profissional, setProfissional] = useState({});
    const [dentes, setDentes] = useState([]);
    const [convenios, setConvenios] = useState([]);
    const [procedimentos, setProcedimentos] = useState([]);
    // variaveis construção tratamento
    const [convenio_id, setConvenio_id] = useState('');
    const [form_tratamento, setForm_tratamento] = useState('');
    const [form_dente, setForm_dente] = useState('');
    const [form_valor, setForm_valor] = useState('');
    const [form_faces, setForm_faces] = useState('');
    const [indicacoes, setIndicacoes] = useState([]);
    const [total, setTotal] = useState(0);
    const [procedimentosDaAvaliacao, setProcedimentosDaAvaliacao] = useState([]);

    useEffect(()=>{
        async function loadDadosCadastroAvaliacao(){
            if(dentes.length==0||procedimentos.length==0||convenios.length==0){
                incluirLoading('buscandoRequisitosAvaliacao');
                const [convenios, dentes, procedimentos] = await Promise.all([
                    fetchCovenios(),
                    fetchDentes(),
                    fetchProcedimentos()
                ]);
                excluirLoading('buscandoRequisitosAvaliacao');

                dispatch({type:'FETCH_CONVENIOS', payload:convenios});
                dispatch({type:'FETCH_DENTES', payload:dentes});
                dispatch({type:'FETCH_PROCEDIMENTOS', payload:procedimentos});
            }
        }

        loadDadosCadastroAvaliacao();
    }, []);

    useEffect(()=>{
        setButtonTratamento(refButtonTratamento);
        setButtonDente(refButtonDente);
        setbuttonFechar(refButtonFechar);
    },[refButtonTratamento, refButtonDente, refButtonFechar]);

    useEffect(()=>{
        setDentes(reduxDentes);
        setConvenios(reduxConvenios);
        setProcedimentos(reduxProcedimentos);
    }, [reduxDentes, reduxProcedimentos, reduxConvenios]);
    
    useEffect(()=>{
        setConvenio_id(reduxAgendamento.convenio_id);
        setAgendamento(reduxAgendamento);

    },[reduxAgendamento]);

    useEffect(()=>{
        setProfissional(storageProfissional);
    },[/* storageProfissional */]);//causa um loop de erros(apenas no local:3000!?),mas não causa grande interferencia

    useEffect(()=>{
        M.AutoInit();
    },[convenios]);

    useEffect(()=>{
        let total = 0;
        reduxProcedimentosAvaliacao.map(e=>{
            total+=parseFloat(e.valor);
        });

        setProcedimentosDaAvaliacao(reduxProcedimentosAvaliacao);


        setTotal(total);
    },[reduxProcedimentosAvaliacao]);

    function incluirLoading(id){
        let arr = loadingArr;
        arr.push(id);
    }

    function excluirLoading(id){
        let arr = loadingArr.filter(e=>e!=id);

        setLoadingArr(arr);
    }
    
    function handlerConvenio(e){
        setConvenio_id(e.target.value);
    }

    function handlerValor(e){
        setForm_valor(e.target.value);
    }   

    function handlerFaces(e){
        setForm_faces(e.target.value);
    }   

    function handlerTratamento(item){
        setForm_tratamento(item);
        if(item){
            setForm_valor(item.preco);
        }else{
            setForm_valor('');
        }
    }   
    
    function handlerDente(item){
        setForm_dente(item);
    }   

    function handleIndicacoes(value){
        let obj={};

        eval(`
            if(indicacoes.${value}){
                obj.${value} = false;
            }else{
                obj.${value} = true;
            }`
        );

        setIndicacoes({...indicacoes,...obj});
    }

    function validProcedimento(obj){
        let valido = true;
        procedimentosDaAvaliacao.map(e=>{
            if((e.dente_nome==obj.dente.codigo)&&
                (e.faces == obj.faces)&&
                (e.produto_id == obj.tratamento.id)&&
                (obj.tratamento.id!=1113&&obj.tratamento.id!=1599&&obj.tratamento.id!=1584)){
                alert('Tratamento já adicionado ao dente, verifique a necessidade do tratamento!');
                valido= false;
            }
        });

        return valido;
    }

    function calcularTotal(procedimentos){
        let total = 0;
        procedimentos.map(e=>{
            total+=parseFloat(e.valor);
        })

        setTotal(total);
    }

    function adicionarTratamentos(obj){

        if(obj.tratamento){
            if(Object.keys(obj.tratamento).length==0){
                alert('Informe o Procedimento para continuar por favor!');
                return ;
            }
        }else{
            alert('Informe o Procedimento para continuar por favor!');
            return ;
        }

        if(obj.dente){
            if(Object.keys(obj.dente).length==0){
                alert('Informe o Dente ou Região para continuar por favor!')
                return ;
            }
        }else{
            alert('Informe o Dente ou Região para continuar por favor!')
            return ;
        }

        if(obj.tratamento.faces_dentes === true && !obj.faces ){
            alert('Informe as faces para continuar por favor!');
            return ;
        }else if(obj.tratamento.faces_dentes === true){
            obj.faces = obj.faces.toUpperCase();

            if(obj.tratamento.qtde_faces !== obj.faces.length){
                alert('Quantidade de faces invalidas, verifique o procedimento ou as faces solicitadas por favor!');
                return ;
            }

            let f = ['O', 'L', 'M', 'V', 'D', 'I', 'P'].sort();

            for(let i in obj.faces){
                let achou = false;

                f.map(e=>{
                    if(e === obj.faces[i]){
                        achou = true;
                    }
                });

                if(!achou){
                    alert('Faces informadas invalidas, por favor verifique as faces solicitadas!');
                    return ;
                }
            }
        }

        let valido = validProcedimento(obj);
        if(valido){
            let aux = {
                valor: obj.valor, 
                faces: obj.faces, 
                dente_id: obj.dente.id, 
                dente_nome: obj.dente.codigo, 
                produto_id: obj.tratamento.id, 
                produto_nome: obj.tratamento.texto, 
            };

            procedimentosDaAvaliacao.push(aux);
            calcularTotal(procedimentosDaAvaliacao);

            setForm_tratamento({});
            setForm_dente({});
            setForm_valor('');
            setForm_faces('');
            buttonTratamento.click();
            buttonDente.click();
        }

    }

    function prepararVerItens(obj){
        let aux = {
            agendamento_id: parseInt(obj.agendamento.agendamento_id),
            convenio_id: parseInt(obj.convenio_id),
            ...obj.indicacoes,
            participante_id: parseInt(obj.agendamento.participante_id),
            pessoa_id: parseInt(obj.profissional.pessoa_id),
            procedimentos:obj.procedimentosDaAvaliacao,
            clearItensAvaliacao:obj.clearItensAvaliacao,
            concluirAvaliacao:concluirAvaliacao
        }

        dispatch({type:'FETCH_NOVA_AVALIACAO', payload:aux});
        dispatch({type:'FETCH_ITENS_NOVA_AVALIACAO', payload:aux.procedimentos});
    }

    function clearItensAvaliacao(){
        dispatch({type:'FETCH_NOVA_AVALIACAO', payload:{}});
        dispatch({type:'FETCH_ITENS_NOVA_AVALIACAO', payload:[]});
    }

    function clearAvaliacao(){
        setAgendamento({});
        setProfissional({});
        // setDentes([]);
        // setConvenios([]);
        // setProcedimentos([]);
        setConvenio_id('');
        setForm_tratamento('');
        setForm_dente('');
        setForm_valor('');
        setForm_faces('');
        setTotal(0);
        setIndicacoes([]);
        setProcedimentosDaAvaliacao([]);


        if(buttonTratamento&&buttonDente){
            buttonTratamento.click();
            buttonDente.click();
        }

        dispatch({type:'FETCH_NOVA_AVALIACAO', payload:{}});
        dispatch({type:'FETCH_ITENS_NOVA_AVALIACAO', payload:[]});
    }

    function concluirAvaliacao(){
        if(buttonFechar)
            buttonFechar.click();
        clearAvaliacao();
    }

    return (
        <div>
            <div id="modalLancarAval" className="modal bottom-sheet">
                { loadingArr.length>0 && <UiLoading type="bubbles" color="#1976de" />}
                <UiHeaderSimple/>
                <div className="modal-content">
                    <UiHeaderTitleModal callback={clearAvaliacao} title="Nova avaliação"/>
                
                    <div className="top-modal" >
                        <div className="doct-info">
                            <div><img src={ft_perfil} className="foto-perfil" alt='imagen de perfil'></img></div>               
                            <div>
                                <span>{agendamento.razao_social}</span><br/>
                                <span>{agendamento.documento}</span><br/>
                            </div>
                        </div>
                    </div>

                    <div className="select-options">
                        <h4>Profissional: {profissional.apelido}</h4>
                        <h4>Qtde. proced.: {procedimentosDaAvaliacao.length} Total: R$ {total}</h4>

                        <div className="input-field input-avalia col s12">
                            <select value={convenio_id?convenio_id:''} onChange={handlerConvenio}>
                                <option default value disabled >Convênio</option>
                                {
                                    convenios.map((e,index)=>{
                                        return <option key={index} value={e.id}>{e.nome}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="row input-avalia">
                            <div className="row-checkbox">
                                <div className="col-checkbox">
                                    <p>
                                        <label>
                                            <input onChange={()=>{handleIndicacoes('indicacao_ortodontica')}} type="checkbox"/>
                                            <span>Ortondontia?</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input onChange={()=>{handleIndicacoes('indicacao_protese')}} type="checkbox"/>
                                            <span>Prótese ?</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input onChange={()=>{handleIndicacoes('indicacao_implante')}} type="checkbox"/>
                                            <span>Implante ?</span>
                                        </label>
                                    </p>
                                </div>
                                <div className="col-checkbox">
                                    <p>
                                        <label>
                                            <input onChange={()=>{handleIndicacoes('indicacao_estetica')}} type="checkbox"/>
                                            <span>Estética ?</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input onChange={()=>{handleIndicacoes('indicacao_endodontia')}} type="checkbox"/>
                                            <span>Endodontia?</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input onChange={()=>{handleIndicacoes('indicacao_periodontia')}} type="checkbox"/>
                                            <span>Periodontia ?</span>
                                        </label>
                                    </p>
                                </div>
                            </div>
                            <Downshift
                                onChange={selection => {handlerTratamento(selection)}}
                                itemToString={procedimentos => (procedimentos ? procedimentos.texto : "")}
                                selectedItem={form_tratamento}>
                                
                                {({
                                    getInputProps,
                                    getItemProps,
                                    getLabelProps,
                                    isOpen,
                                    inputValue,
                                    highlightedIndex,
                                    selectedItem,
                                    clearSelection
                                }) => (
                                    <div>
                                        <label {...getLabelProps()}>Tratamento</label>
                                        <div className="line-autocomplete">
                                            <input {...getInputProps()} />
                                            <a ref={el => refButtonTratamento = el} style={{marginBottom:10}} 
                                                onClick={()=>{clearSelection()}} 
                                                className="waves-effect waves-light btn-floating transparent blue darken-3">
                                                <i className="material-icons left">close</i>
                                            </a>
                                        </div>
                                        {isOpen ? (
                                            <div>
                                                {procedimentos
                                                    .filter(i => {
                                                        if(i.texto){
                                                            return i.texto.toLowerCase().includes(inputValue.toLowerCase());
                                                        }
                                                        return false;
                                                    })
                                                    .slice(0,5)
                                                    .map((item, index) => (
                                                            <div
                                                                {...getItemProps({
                                                                key: index,
                                                                index,
                                                                item:item,
                                                                style: {
                                                                    backgroundColor: highlightedIndex === index
                                                                    ? 'lightgray'
                                                                    : 'white',
                                                                    fontWeight: selectedItem === item
                                                                    ? 'bold'
                                                                    : 'normal',
                                                                },
                                                                })}
                                                                >
                                                                {item.texto}
                                                            </div>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                )}
                            </Downshift>
                        </div>

                        <div className="face-dente-content input-avalia">
                            <Downshift
                                onChange={selection => {handlerDente(selection)}}
                                itemToString={dentes => (dentes ? dentes.codigo : "")}>
                                
                                {({
                                    getInputProps,
                                    getItemProps,
                                    getLabelProps,
                                    isOpen,
                                    inputValue,
                                    highlightedIndex,
                                    selectedItem,
                                    clearSelection
                                }) => (
                                    <div>
                                        <label {...getLabelProps()}>Dente</label>
                                        <div className="line-autocomplete">
                                            <input {...getInputProps()} />
                                            <a ref={el => refButtonDente = el} style={{width: 46,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'}}
                                                onClick={()=>{clearSelection()}} 
                                                className="waves-effect waves-light btn-floating transparent blue darken-3">
                                                <i style={{margin:0}} className="material-icons left">close</i>
                                            </a>
                                        </div>
                                        {isOpen ? (
                                            <div>
                                                {dentes
                                                    .filter(i => {
                                                        if(i.codigo){
                                                            return i.codigo.toLowerCase().includes(inputValue.toLowerCase());
                                                        }
                                                        return false;
                                                    })
                                                    .slice(0,5)
                                                    .map((item, index) => (
                                                    <div
                                                        {...getItemProps({
                                                        key: index,
                                                        index,
                                                        item:item,
                                                        style: {
                                                            backgroundColor: highlightedIndex === index
                                                            ? 'lightgray'
                                                            : 'white',
                                                            fontWeight: selectedItem === item
                                                            ? 'bold'
                                                            : 'normal',
                                                        },
                                                        })}
                                                        >
                                                        {item.codigo} - { item.descricao }
                                                    </div>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                )}
                            </Downshift>
                            <div style={{marginLeft:20}} className="row">
                                <div className="col s12">
                                    <div className="input-field col s12">
                                        <input onChange={handlerFaces} value={form_faces} type="text" id="autocomplete-input" className="autocomplete"></input>
                                        <label htmlFor="autocomplete-input">faces</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="valor-add-btn input-avalia">
                            <div>
                                <div className="row input-left-left">
                                    <div className="col s12">
                                        <div className="input-field col s12">
                                            <input value={form_valor} onChange={handlerValor} type="text" id="autocomplete-input" className="autocomplete"></input>
                                            <label htmlFor="autocomplete-input">Valor</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Button onClick={()=>{adicionarTratamentos({tratamento:form_tratamento,dente:form_dente,valor:form_valor,faces:form_faces,buttonTratamento,buttonDente});}} className="btn waves-effect waves-teal ">Adicionar</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer footer-itens">
                    <a href="#" ref={el => refButtonFechar = el} onClick={clearAvaliacao} className="modal-close waves-effect waves-green btn-flat">voltar</a>
                    <a 
                        onClick={()=>{prepararVerItens({
                        agendamento,
                        profissional,
                        convenio_id,
                        indicacoes,
                        procedimentosDaAvaliacao,
                        clearItensAvaliacao
                    });}} className="itens-btn  waves-effect waves-green btn-flat modal-trigger" href="#modalAddTratamento">Ver Itens</a>
                </div>
            </div>
        </div>
    );
}