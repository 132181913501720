import React, { useState,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../../css/agendamentos.css';

import UiHeader from "../../../componentes/ui/Header";
import UiBotaoMenu from "../../../componentes/ui/BotaoMenu";
import UiCardStatus from "../../../componentes/ui/CardStatus";
import UiModalAgendamentos from "../../../componentes/ui/ModalAgendamentos";
import UiSelectEspecialidades from "../../../componentes/ui/SelectEspecialidades"
import HomeModalAgendamentosPlanos from "../../../componentes/home/ModalAgendamentosPlano";

import {URL_API, URL_SITE} from '../../../constantes/constantes';

import M from "materialize-css";
import {filtroAgendamentos, todosClinicos} from '../../../functions/Filtros/agendamentos'
import {buscaPaciente} from '../../../functions/Filtros/filtros';

import {fetchAgendamentosDoDia, fetchAgendamentosClinicos, fetchTrocaProfissional} from '../../../store/reducers/agendamentos';
import {fetchVerificaPainel, fetchChamarPainel} from '../../../store/reducers/Painel';

import UiLoading from '../../../componentes/ui/Loading';
// import io from 'socket.io';

export default function ScreensAutorizadoAgendamentos(){

    const reduxAgendamentos=useSelector(state=>state.agendamentos.agendamentos);
    const reduxClinicos = useSelector(state => state.agendamentos.allClinicos);
    const reduxTrocaProfissional = useSelector(state => state.agendamentos.troca_profissional);
    const reduxVerificaPainel = useSelector(state => state.painel.verifica_painel);
    const reduxChamarPainel = useSelector(state=>state.painel.chamar_painel);
    const reduxCont = useSelector(state=>state.cont)

    const dispatch = useDispatch();

    const tiposAtendimento = ["avaliação", "atendimento", "retorno médico", "avaliação orto", "manutenção orto"];
    const statusAtendimento = ["marcado", "confirmado", "agenda excluída", "pendência recebimento", "na fila para atendimento", "sala do profissional", "término de atendimento", "não atendido", "ausente", "ocorrência"];
    const turnos = ["manhã", "tarde"];

    const [agendamentos, setAgendamentos] = useState([]);
    const [allClinicos, setClinicos] = useState([]);
    const [troca_profissional, setTrocaProfissional] = useState();
    const [verifica_painel, setVerificaPainel] = useState({});
    const [chamar_painel, setChamarPainel] = useState({});

    const [contReload, setContReload] = useState(0);
    const [filtrosValores, setFiltrosValores] = useState({});
    const [tituloDrop, setTituloDrop] = useState("Selecionar Agendamentos")
    const [listarAgendamento, setListarAgendamento] = useState([]);
    const [descricaoLista, setDescricaoLista] = useState("todos");
    const [itemVisualizacao, setItemVisualizacao] = useState("");
    const [agendamentoChamarPainel, setAgendamentoChamarPainel] = useState({});
    const [datas, setDatas] = useState([]);
    const [dataSelecionada, setDataSelecionada] = useState([]);
    const [searchInput, setSearchInpu] = useState();
    const [searchResults, setSearchResults] = useState([]);
    // loading
    const [loadingArr, setLoadingArr] = useState([]);

    useEffect(()=>{
    
        async function loadAgendamentos(){
            let data = new Date();
            M.AutoInit();
            
            incluirLoading('buscandoAgendamento');
            
            let agendamentos = await fetchAgendamentosDoDia(`${data.getFullYear()}-${(data.getMonth()+1)}-${data.getDate()}`);
            
            // agendamentos.sort(function(a,b) {
            //     let x = a.inicio.split(" ")[1];
            //     let y = b.inicio.split(" ")[1];
                
            //     return  x < y ? -1 : x < y ? 1 : 0;
            // });
            
            excluirLoading('buscandoAgendamento');  
            
            setDataSelecionada(`${data.getDate()<10?"0"+data.getDate():data.getDate()}/${(data.getMonth()+1)<10?"0"+(data.getMonth()+1):(data.getMonth()+1)}/${data.getFullYear()}`);
            
            dispatch({type:'FETCH_AGENDAMENTOS_DIA', payload:agendamentos});   
            
        }loadAgendamentos(); 
        
    },[]);
    

    useEffect(()=>{
        let dataAtual = new Date(), datas= [], dayName = ["D", "S", "T", "Q", "Q", "S", "S"];
    
        dataAtual.setDate(dataAtual.getDate()-3);
        for(let i=1;i<=7;i++){
            if(i!=1){
                dataAtual.setDate(dataAtual.getDate()+1);
            }
            datas.push({ano:dataAtual.getFullYear(),mes:(dataAtual.getMonth()+1),dia:dataAtual.getDate(),nome:dataAtual.getDay([dayName])+0,dn:dayName});
            datas.sort();
            
        }setDatas(datas);
    },[]);

    useEffect(()=>{
        let planosArray = filtroAgendamentos(reduxAgendamentos);

        setAgendamentos(reduxAgendamentos); setSearchResults(reduxAgendamentos);
        setFiltrosValores(planosArray.filtrosValores);
    },[reduxAgendamentos]);

    useEffect(()=>{
        let filtroAgendamento = filtroAgendamentos(reduxAgendamentos)
        let a = filtrosValores.filtroAgendamento;
        setAgendamentos(a); setSearchResults(a); 
    },[agendamentos]);

    useEffect(()=>{
        //filtrando agendamentos para não mostrar os terminos de atendimento
        let all = todosClinicos(reduxClinicos);
        let b = (all.filtrosValores.allClinicosGerais);
        setClinicos(b); setSearchResults(b);
        // setClinicos(reduxClinicos);
    },[reduxClinicos]);

    useEffect(()=>{
        setChamarPainel(reduxChamarPainel);
        setVerificaPainel(reduxVerificaPainel);
        setTrocaProfissional(reduxTrocaProfissional);
    },[reduxChamarPainel]);
    
    useEffect(()=>{
        const results = searchResults.filter((e)=>
            ((e.razao_social.toLowerCase().includes(searchInput.toLowerCase())
                ||(e.documento&&e.documento.includes(searchInput)))
                ||""));
        
        setSearchResults(results);
    },[searchInput]);

    async function atualizarAgendamentos(obj){
        let data = new Date(obj.ano,(obj.mes-1),obj.dia,0,0,0);
        incluirLoading('atualizaAgendamentos');
        let agendamentos = await fetchAgendamentosDoDia(`${data.getFullYear()}-${(data.getMonth()+1)}-${data.getDate()}`);
        excluirLoading('atualizaAgendamentos');
        setDataSelecionada(`${data.getDate()}/${(data.getMonth()+1)}/${data.getFullYear()}`);
        setSearchResults(agendamentos);
        dispatch({type:'FETCH_AGENDAMENTOS_DIA', payload:agendamentos});
    }

    async function att(){
        let data = new Date();
        incluirLoading('atualizaAgendamentos');

        let agendamentos = await fetchAgendamentosDoDia(`${data.getFullYear()}-${(data.getMonth()+1)}-${data.getDate()}`);

        dispatch({type:'FETCH_AGENDAMENTOS_DIA', payload:agendamentos});

        excluirLoading('atualizaAgendamentos');

        setTituloDrop("Meus Agendamentos");

        let filtroAgendamento = filtroAgendamentos(reduxAgendamentos)
        let a = (filtrosValores.filtroAgendamento);
        setAgendamentos(a); setSearchResults(a);    

        setContReload(contReload+1);
    }

    async function loadAllClinicos(){
        let data = new Date();

        incluirLoading('carregarClinicos');
            setTituloDrop("Todos os Clinicos");
        
            let allClinicos = await fetchAgendamentosClinicos(`${data.getFullYear()}-${(data.getMonth()+1)}-${data.getDate()}`);
        excluirLoading('carregarClinicos');
    
        dispatch({type:'FETCH_AGENDAMENTOS_CLINICOS', payload:allClinicos});

        setContReload(contReload+1);
    }

    if(contReload>3){
        document.location.reload();
        // document.location.replace(URL_SITE)
    }

    async function verificaPainel(agendamento){//retirar função após evoluir o chamar painel para promisse all
        let verifica_painel = await fetchVerificaPainel(agendamento);
        dispatch({type:'FETCH_VERIFICA_PAINEL', payload:verifica_painel});
    }

    async function trocaProfissional(agendamento){
        let troca_profissional = await fetchTrocaProfissional(agendamento);
        dispatch({type:'FETCH_TROCA_PROFISSIONAL', payload:troca_profissional});
    }

    async function chamarPainel(agendamento){//evoluir para um promisse.all que pegue o verifica painel a chamada do painel e a troca do profissional
        let data = new Date();
        

        // incluirLoading('chamandoPacientePainel');
        
        let chamar_painel = await fetchChamarPainel(agendamento);
        dispatch({type:'FETCH_CHAMAR_PAINEL', payload:chamar_painel});

        // excluirLoading('chamandoPacientePainel');
        // verificaPainel(agendamento);

        
        if(chamar_painel){
            if(chamar_painel.err){
                handlerClose();
            }
        }else{

            incluirLoading('chamandoPacientePainel');

            trocaProfissional(agendamento);
    
            let agendamentos = await fetchAgendamentosDoDia(`${data.getFullYear()}-${(data.getMonth()+1)}-${data.getDate()}`);
            dispatch({type:'FETCH_AGENDAMENTOS_DIA', payload:agendamentos});
            setTituloDrop("Meus Agendamentos"); setSearchResults(agendamentos);

            excluirLoading('chamandoPacientePainel');
        }

    }

    function listarArray(valor){
        if(valor=="convenio"){
            setListarAgendamento(filtrosValores.planosArray);
            setDescricaoLista("convenio");
        }else if(valor=="especialidade"){
            setListarAgendamento(filtrosValores.especialidadesArray);
            setDescricaoLista("nome_abreviado_sb");
        }else if(valor=="empresa"){
            setListarAgendamento(filtrosValores.empresasArray);
            setDescricaoLista("empresa");
        }else if(valor=="grupo"){
            setListarAgendamento(filtrosValores.gruposArray);
            setDescricaoLista("grupo");
        }else if(valor=="status"){
            setListarAgendamento(filtrosValores.statusArray);
            setDescricaoLista("status");
        }else if(valor=="tipo"){
            setListarAgendamento(filtrosValores.tiposArray);
            setDescricaoLista("tipo");
        }else if(valor == "turno"){
            setListarAgendamento(filtrosValores.turnoArray);
            setDescricaoLista("turno")
        }else if(valor=="todos"){
            setDescricaoLista("todos");
        }
    }

    function handlerItem(item){
        setItemVisualizacao(item);
    }

    function handlerChamarpainel(agendamento){
        setAgendamentoChamarPainel(agendamento);
    }

    function handlerClose(){
        if(tituloDrop == "Meus Agendamentos"){
            att();
        }else if (tituloDrop == "Todos os Clinicos"){
            loadAllClinicos();
        }else{
            att();
        }
    }

    function handleSearch(searchInput){
        setSearchInpu(searchInput)
        /* caso o filtro seja apenas para os pacietes de cada tela */
        if(tituloDrop == 'Selecionar Agendamentos'|| tituloDrop=='Meus Agendamentos'){
            var pacientesConcat = agendamentos;
        }else if(tituloDrop == "Todos os Clinicos"){
            var pacientesConcat = allClinicos;
        }

        setSearchResults(pacientesConcat);

        /* caso seja para filtrar todos os pacientes independente da tela */
        // if(allClinicos && agendamentos){
        //     let pacientesConcat = agendamentos.concat(allClinicos);

        //     //como os arrays são concatenados, alguns pacientes ficam duplicados. removendo valores duplicado
        //     const uniqueAddresses = Array.from(new Set(pacientesConcat.map(a => a.participante_id)))
        //     .map(participante_id => {
        //         return pacientesConcat.find(a => a.participante_id === participante_id)
        //     })

        //     setSearchResults(uniqueAddresses);
        // }
    }

    const backButton=()=>{
        console.log("botão voltar");
    }
    const goButton=()=>{
        console.log("botão avançar");
    }

    function incluirLoading(id){
        let arr = loadingArr;
        arr.push(id);
        console.log(arr,"incluir")
    }

    function excluirLoading(id){
        let arr = loadingArr.filter(e=>e!=id);
        
        setLoadingArr(arr);
        console.log(arr,"excluir")
    }


    return(
        
        <div>
            { loadingArr.length>0 && <UiLoading type="bubbles" color="#1976de" />}
            <UiHeader callback={(data)=>{handleSearch(data)}}/> {/*pacientes={agendamentos} clinicos={allClinicos}*/}
            <UiBotaoMenu/>
            <UiModalAgendamentos /* callbackParent={(bool) => onChildChange(bool)}  */ agenda={true} callback={handlerClose}/>
            <HomeModalAgendamentosPlanos callback={handlerChamarpainel} agendamentosDoConvenio={listarAgendamento[itemVisualizacao]} title={descricaoLista}/>

            <div className="agenda-content">

                {/*inicio area calendario */}
                <div className="reservado">
                    <div className="reservado-content">
                    
                        {/*div calendario */}
                        <div className="calendario-content">

                            {/*div numero de agendamentos*/}
                            <div className="num-agend-content">
                                <div className="num-agend">
                                    {
                                        tituloDrop !== "Todos os Clinicos"?<span><b>{agendamentos?agendamentos.length:0}</b></span>:<span><b>{allClinicos?allClinicos.length:0}</b></span>
                                    }
                                </div>
                            </div>
                            <div className="calendario">

                                {/* <div className="btn-comando btn-voltar" onClick={()=>{backButton()}}>
                                    <i className="small material-icons icones left">chevron_left</i>
                                </div> */}

                                <div className="calendar-table">
                                    {datas.map((e,index)=>{
                                        return (<div key={index} onClick={()=>{atualizarAgendamentos(e)}} className="data-table">
                                                    {/* <span className="semana">{e.dn[e.nome]}</span> */}
                                                    <span className="dia">{e.dia}</span>
                                                </div>);
                                    })}
                                </div>
                                {/* 
                                <div className="btn-comando btn-avancar" onClick={()=>{goButton()}}>
                                    <i className="small material-icons right">chevron_right</i>
                                </div> */}
                            </div>
                        </div>
                        <div className="data-display">
                            <span className="data-ano">{dataSelecionada}</span>
                        </div>
                    </div>
                </div>
                {/*fim area calendario */}


                <div className="conteudo body-info"> 
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                        <div>
                            <a className="waves-effect waves-teal btn-flat btn-small" onClick={()=>{att()}} style={{backgroundColor:'#fff !important'}}>
                                <i className="large material-icons">refresh</i>
                            </a>
                        </div>
                        {/* <UiSelectEspecialidades/> */}
                        <div style={{display:'flex'}}>
                            <a className="waves-effect waves-teal btn-flat btn-small dropdown-trigger" href="#" data-target="dropdownClinicos">{tituloDrop}</a>

                            <ul id="dropdownClinicos" className="dropdown-content drops-info">
                                <li><a href="#!" onClick={()=>{att()}}>Meus Agendamentos</a></li>
                                <li className="divider" tabIndex="-1"></li>
                                <li><a onClick={()=>{loadAllClinicos()}}>Todos os Clinicos</a></li>
                            </ul>
                        </div>

                        <div className="filtro-data">
                            <a className="dropdown-trigger icones-a right" href="#" data-target="dropdown1"> 
                                <i className="material-icons">sort</i>
                            </a>
                            <ul id="dropdown1" className="dropdown-content drops-info">
                                <li><a href="#!" onClick={()=>listarArray("convenio")}>Convênio</a></li>
                                <li><a href="#!" onClick={()=>listarArray("especialidade")}>Especialidade</a></li>
                                <li><a href="#!" onClick={()=>listarArray("empresa")}>Empresa</a></li>
                                <li><a href="#!" onClick={()=>listarArray("grupo")}>Grupo</a></li>
                                <li><a href="#!" onClick={()=>listarArray("status")}>Status</a></li>
                                <li><a href="#!" onClick={()=>listarArray("tipo")}>Tipo</a></li>
                                <li><a href="#!" onClick={()=>listarArray("turno")}>Turno</a></li>
                                <li className="divider" tabIndex="-1"></li>
                                <li><a href="#!" onClick={()=>listarArray("todos")}>Todos</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="body-dash">
                        <div className="body-dash-inner">
                            <div className="lista-body">
                                <ul className="collection body-dash-collection">
                                    {
                                        descricaoLista!="todos"?
                                            listarAgendamento?listarAgendamento.map((e,index)=>{
                                                return <a className="collection-item waves-effect waves-light modal-trigger" onClick={()=>{handlerItem(index)}} href="#modalDetalheConvenio" key={index}><b>{e.length}</b> {(descricaoLista!='status'&&descricaoLista!='tipo')?
                                                                                                                                                                                                                                eval(`e[0].${descricaoLista}.toLowerCase()`)
                                                                                                                                                                                                                            :descricaoLista=='status'?
                                                                                                                                                                                                                                eval(`statusAtendimento[(e[0].${descricaoLista}-1)]`)
                                                                                                                                                                                                                                :descricaoLista=='tipo'?eval(`tiposAtendimento[(e[0].${descricaoLista}-1)]`):null}</a>                            
                                            }):<span>null</span>
                                        :null
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-cards">
                        {/* agendados d@ medic@ */}
                        {
                            agendamentos?
                                (agendamentos.length<1 && tituloDrop!=="Todos os Clinicos")?
                                    <span style={{display:'flex', justifyContent:'center'}}>Você não tem novos agendamentos</span>
                                :descricaoLista=="todos" && tituloDrop!=="Todos os Clinicos"?
                                    searchResults.map((e)=>{
                                        return <UiCardStatus callback={(data)=>{handlerChamarpainel(data)}}  key={e.agendamento_id} agendamento={[e]} />
                                    })
                                :null
                            :null
                        }

                        {/* todos os clinicos */}
                        {
                            descricaoLista=="todos" && tituloDrop=="Todos os Clinicos"?
                                allClinicos?searchResults.map((e,index)=>{
                                    return <UiCardStatus callback={(data)=>{handlerChamarpainel(data)}}  key={e.agendamento_id} agendamento={[e]} />
                                }):<span>null</span>
                            :null
                        }

                        <div id="modal1" className="modal">
                            <div className="modal-content">
                            <h4>Smartjob informa</h4>
                            <p>Deseja chamar o paciente para o consultório?</p>
                            </div>
                            <div className="modal-footer">
                                <a href="#!" className="modal-close waves-effect waves-green btn-flat">Não</a>
                                <a href="#" onClick={()=>{chamarPainel(agendamentoChamarPainel)}} className="modal-close waves-effect waves-green btn-flat">Sim</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    
    );
}