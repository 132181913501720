import React from "react";

import { useState, useEffect } from 'react';

import M from "materialize-css";
import { Icon } from 'react-materialize';

import {filtroData} from '../../functions/Filtros/filtros';

export default function UiCardTratamentoEvolucao(props) { 
    const [tratamento,setTratamento]=useState(props.tratamento?props.tratamento[0]:{})
    const [DataFormatada, setFiltroData] = useState(tratamento.data);


    useEffect(()=>{
        let dataFormat = filtroData(tratamento.data)
        setFiltroData(dataFormat)
    })
    const infoStyle ={
        fontWeight: 300,
        // backgroundColor: '#000'
    };
    const tituloStyle={
        display:'flex',
        // justifyContent:'center',
        marginBottom:'5px',
        // borderBottom:'1px solid rgb(32, 90, 120)'
    }

    return(
        <div className="card">
            <div className="card-content content-trata">
                <div className="card-info card-trata">
                    <span style={tituloStyle}>{tratamento.titulo?tratamento.titulo:null} </span>
                    <span>Dente/Região: <b style={infoStyle}>{tratamento.dente_regiao?tratamento.dente_regiao:null}</b> - face: <b style={infoStyle}>{tratamento.face_dente?tratamento.face_dente:null}</b></span>
                    <span>Data: <b style={infoStyle}>{DataFormatada} </b></span>
                    <span>Profissional: <b style={infoStyle}>{tratamento.profissional}</b></span>
                </div>
            </div>
        </div>
    );
}