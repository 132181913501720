import React, { Component } from 'react';
import RouterApp from './screens/Routes';
import 'materialize-css/dist/css/materialize.min.css';
import M from "materialize-css";
import  "./css/ajustes.css";
import "./css/geral.css";

import {createStore, applyMiddleware} from 'redux';
import ReduxPromise from 'redux-promise';
import {Provider} from 'react-redux';
import reducer from './store';

const createStoreWithMiddleware= applyMiddleware(ReduxPromise)(createStore);
const store = createStoreWithMiddleware(reducer);


class App extends Component {

  componentDidMount(){
    M.AutoInit();
  }

  render() {
    return (
      <Provider store={store}> 
        <RouterApp />
      </Provider>
    );
  }
}

export default App;
