import React from 'react';

import M from "materialize-css";

import { Link } from "react-router-dom";
import {useEffect} from 'react';

export default function UiBotaoMenu(){
    useEffect(()=>{
        M.AutoInit();
    },[]);

    return(
        <div>
            <div className="fixed-action-btn">
                <a className="btn-menu btn-floating btn-large">
                    <i className="large material-icons">expand_less</i>
                </a>
                <ul>
                    {/* <li><Link to="/user/repasses" className="btn-floating"><i className="material-icons">show_chart</i></Link></li> */}
                    <li><Link to="/user/agendamentos" className="btn-floating"><i className="material-icons"> event_note</i></Link></li>
                    <li><Link to="/user/home" className="btn-floating"><i className="material-icons">home</i></Link></li>
                </ul>
            </div>
        </div>
    );
}