import React from 'react';
import { useState,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import M from "materialize-css";

import {fetchAgendamentosMes} from '../../store/reducers/agendamentos';

import UiLoading from './Loading';
import UiHeaderSimple from './HeaderSimple.js';

import {filtroData} from '../../functions/Filtros/filtros';
import {StatusCores} from '../../functions/Filtros/agendamentos';

export default function UiModalAgendadosMes(props){

    const reduxAgendamentosMes = useSelector(state=>state.agendamentos.agendamentoMes);
    const dispatch = useDispatch();

    const [agendamentosMes, setAgendamentosMes] = useState([]);
    const [loadingArr, setLoadingArr] = useState([]);
    const [mesesSelect, setMesesSelect] = useState([]);
    const [tituloDrop, setTituloDrop] = useState("Selecione um Mês");
    const [mesEcolhido, setMesEscolhido] = useState();


    const statusAtendimento =[
        {nome:'marcado',abrev:"M"},
        {nome:"confirmado", abrev:"C"},
        {nome:"agenda excluida",abrev:"E"},
        {nome:"pendencia recebimento", abrev:"P"},
        {nome:"fila de espera",abrev:"F"},
        {nome:"sala profissional", abrev:"S"},
        {nome:"término de atendimento",abrev:"T"},
        {nome:"não atendido", abrev:"T"},
        {nome:"ausente",abrev:"A"},
        {nome:"ocorrência", abrev:"O"}
    ];

    let clinicas = JSON.parse(localStorage.getItem('empresa'))
    let empresa = clinicas.apelido


    useEffect(()=>{
        
        async function loadAgendamentos(){
            // M.AutoInit()
            let data = new Date();
            
            incluirLoading('buscandoAgendamento');
            
            let agendamentosMes = await fetchAgendamentosMes(`${(data.getMonth()+1)}`,`${data.getFullYear()}`);
            
            agendamentosMes.sort(function(a,b) {
                let x = a.data_abertura.split(" ")[1];
                let y = b.data_abertura.split(" ")[1];
                
                return  x < y ? -1 : x < y ? 1 : 0;
            });
            
            excluirLoading('buscandoAgendamento');  
            
            
            dispatch({type:'FETCH_AGENDAMENTOS_MES', payload:agendamentosMes});   
            
        }loadAgendamentos(); 
        
    },[]);

    useEffect(()=>{

        setAgendamentosMes(reduxAgendamentosMes);

    },[reduxAgendamentosMes]);

    useEffect(()=>{
        let date = new Date();
        let data = [];
        let meses = ['janeiro','fevereiro','março','abril','maio','junho','julho','agosto','setembro','outubro','novembro','dezembro'];
        let mesesEscolha = [];

        date.setMonth(date.getMonth()-2);

        for(let i = 1; i<4; i++){

            if(i!=1){
                date.setMonth(date.getMonth()+1)
            }
            data.push({mes:(date.getMonth()+1)})
        }
        for(let i = 0; i<data.length; i++){ 
        
            mesesEscolha.push({nome:meses[data[i].mes-1],valor:data[i].mes}) 
            
        }
        setMesesSelect(mesesEscolha)

    },[]);

    async function escolherMes(value){

        let data = new Date();

        console.log(value.valor,"mes");
        setTituloDrop(value.nome);
        setMesEscolhido(value.valor);

        incluirLoading("carregarAg");

        let agendamentosMes = await fetchAgendamentosMes(value.valor,`${data.getFullYear()}`);

        excluirLoading("carregarAg");
        
        dispatch({type:'FETCH_AGENDAMENTOS_MES', payload:agendamentosMes});   
    }


    function incluirLoading(id){
        let arr = loadingArr;
        arr.push(id);
    }

    function excluirLoading(id){
        let arr = loadingArr.filter(e=>e!=id);

        setLoadingArr(arr);
    }

    const Style={
        center:{
            display:'flex',
            justifyContent:'center',
            alignItems:'center  '
        },
        border:{
            border:'1px solid rgb(42, 128, 192)',
            // backgroundColor:'rgb(26, 172, 227)',
            borderRadius: "40px",
        },
        font:{
            fontFamily: 'Montserrat, sans-serif'
        },
        qtdeStyle:{
            // border:'1px solid rgb(132, 197, 167)',
            border:'solid 1px rgb(42, 128, 192)',
            color:'#000',
            borderRadius:'30px',
            padding:'0px 10px',  
        },
    }


    return(
        <div>
            <div id="modalAgendadosMes" className="modal bottom-sheet">
                { loadingArr.length>0 && <UiLoading type="bubbles" color="#1976de" />}
                
                {/* <UiHeaderSimple/> */}
                {/* <div className="modal-content"> */}

                    <div className="modal-footer width-close" style={{display:'flex',maxWidth:'100%', height:'30px',justifyContent:'flex-end',backgroundColor:'rgb(154, 196, 167)'}}>
                        <div>
                            <a href="#" className="modal-close waves-effect waves-green btn-flat " style={{display:'flex',width:'100%' }}><i className="material-icons" style={{color:'#000'}}>close</i></a>
                        </div>
                    </div>

                    <div style={{display:'flex',backgroundColor:'rgb(154, 196, 167)',height:'20vh', justifyContent:'center'}}>
                        <div  style={{display:'flex', flexDirection:"column"}}>
                            <div style={{display:'flex',width:'100%', justifyContent:'center',marginTop:'30px', fontSize:'16pt', color:'#000', fontFamily: 'Montserrat, sans-serif'}}>
                                <span>Lista de Atendidos no Mês</span>
                            </div>

                        </div>
                    </div>

                    <div style={{margin:'-40px 10px'}}>
                        <div className="row" style={{marginBottom:'0px', lineHeight:'0.80',  fontFamily: 'Montserrat, sans-serif'}}>
                            <div className="col s12 m5"> 
                                <div className="card-panel teal" style={{padding:'20px'}}>
                                    <div className="row-checkbox" style={{marginBottom:'20px', alignItems:'center', lineHeight:'20px'}}>
                                        <div>
                                            <span className="black-text" style={Style.font}><b>Empresa: </b>{empresa.toLowerCase()}</span>
                                        </div>

                                        <div>
                                            <a className='btn-flat btn-small tooltipped black-text' style={Style.font, Style.qtdeStyle} data-position="bottom" data-tooltip="Quantidade de Atendidos">{agendamentosMes.length}</a>
                                            {/* <span className="black-text" style={Style.font, Style.qtdeStyle}>{agendamentosMes.length}</span>  */}
                                        </div>
                                    </div>

                                    <div style={Style.center ,Style.border}>
                                        <a className="waves-effect waves-teal btn-flat btn-small dropdown-trigger" style={Style.center} href="#" data-target="dropMesEscolha">
                                            {tituloDrop}  
                                            <i className="material-icons">expand_more</i>
                                        </a>

                                        <ul id="dropMesEscolha" className="dropdown-content drops-info">
                                            {
                                                mesesSelect?mesesSelect.map((e,index)=>{
                                                    return (
                                                        <div key={index}>
                                                            <li onClick={()=>{escolherMes(e)}} style={Style.center}><a>{mesesSelect?e.nome:null}</a></li>
                                                            <li className="divider" tabIndex="-1"></li>
                                                        </div>
                                                    )
                                                }):null
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            agendamentosMes?agendamentosMes.map((e, index)=>{
                                return (
                                    <div className="row" style={{marginBottom:'0px', lineHeight:'0.80',  fontFamily: 'Montserrat, sans-serif'}} key={index}>
                                        <div className="col s12 m5"> 
                                            <div className="card-panel teal">
                                                <p className="black-text"><b>Paciente: </b>{e.razao_social.toLowerCase()}</p>
                                                <p className="black-text"><b>Especialidade: </b>{e.desc_sub_grupo.toLowerCase()}</p>
                                                <p className="black-text"><b>Data: </b>{filtroData(e.data_abertura)}</p>
                                                {/* <p className="black-text"><b>Empresa:</b> {e.empresa.toLowerCase()}</p> */}

                                                <div style={{marginBottom:'25px'}} >
                                                    <span style={{display:'flex', justifyContent:'center', padding:'0px'}} className={`badge badge-btn ${StatusCores(e)}`}>{agendamentosMes?statusAtendimento[(e.status-1)].abrev:null}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }):null
                        }

                    </div>

                    <div className="modal-footer">
                        <a href="#" className="modal-close waves-effect waves-green btn-flat">Voltar</a>
                    </div>
                {/* </div> */}
            </div>
        </div>
    )
}