import {URL_API, URL_SITE} from '../../../constantes/constantes';
import {removerCredenciaisDeLogin} from '../../../functions/auth/authLogin';

const types={
    FETCH_AVALIACOES:"FETCH_AVALIACOES",
    FETCH_TRATAMENTOS:"FETCH_TRATAMENTOS",
    FETCH_CONVENIOS:"FETCH_CONVENIOS",
    FETCH_DENTES:"FETCH_DENTES",
    FETCH_PROCEDIMENTOS:"FETCH_PROCEDIMENTOS",
    FETCH_ITENS_NOVA_AVALIACAO:"FETCH_ITENS_NOVA_AVALIACAO",
    FETCH_NOVA_AVALIACAO:"FETCH_NOVA_AVALIACAO",
    FETCH_ADD_FOTO:"FETCH_ADD_FOTO", //adicionado
};

const INITIAL_STATE={
    avaliacoes:[],
    tratamentos:{},
    dentes:[],
    procedimentos:[],
    convenios:[],
    nova_avaliacao:{},
    procedimentos_nova_avaliacao:[], //adicionado
    add_foto:[], //adicionado
};


export function prontuario(state = INITIAL_STATE, action){
    switch(action.type){
        case types.FETCH_AVALIACOES:
            return {...state, avaliacoes:action.payload};
        case types.FETCH_TRATAMENTOS:
            return {...state, tratamentos:action.payload};
        case types.FETCH_CONVENIOS:
            return {...state, convenios:action.payload};
        case types.FETCH_DENTES:
            return {...state, dentes:action.payload};
        case types.FETCH_PROCEDIMENTOS:
            return {...state, procedimentos:action.payload};
        case types.FETCH_NOVA_AVALIACAO:
            return {...state, nova_avaliacao:action.payload};
        case types.FETCH_ITENS_NOVA_AVALIACAO:
            return {...state, procedimentos_nova_avaliacao:action.payload};
        case types.FETCH_ADD_FOTO: //adicionado
            return {...state, add_foto:action.payload}; //adicionado
        default:
            return state;
    }
}




export const fetchAvaliacoes=async (participante_id)=>{
    let auth=JSON.parse(localStorage.getItem('auth'));
    let avaliacoes = await fetch(`${URL_API}/prontuarios/${participante_id}/avaliacoes`,{
        headers:{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "X-Auth-Token":auth.token
        },
        method:'get'
    })
    .then(res=>res.json())
    .then((resJson)=>{
        if(resJson.status=='ok'){
            return resJson.data;
        }else{
            alert(resJson.mensagem);
            return [];
        }
    }).catch(err=>{
        alert("Houve erros na execução");
        console.log(err);
        return [];
    });

    return avaliacoes;
}

export const fetchItensAvaliacao=async (tratamento_id)=>{
    let auth=JSON.parse(localStorage.getItem('auth'));
    let itens = await fetch(`${URL_API}/tratamentos/${tratamento_id}`,{
        headers:{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "X-Auth-Token":auth.token
        },
        method:'get'
    })
    .then(res=>res.json())
    .then((resJson)=>{
        if(resJson.status=='ok'){
            return resJson.data;
        }else{
            alert(resJson.mensagem);
            // return array vazio caso de erro e retorno da api esperado for array
            // return objeto vazio caso de erro e retorno da api esperado for objeto
            return [];
        }
    }).catch(err=>{
        alert("Houve erros na execução");
        console.log(err);
        return {};
    });

    return itens;
}

export const fetchCovenios=async ()=>{
    let auth=JSON.parse(localStorage.getItem('auth'));
    let itens = await fetch(`${URL_API}/convenios/all`,{
        headers:{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "X-Auth-Token":auth.token
        },
        method:'get'
    })
    .then(res=>res.json())
    .then((resJson)=>{
        return resJson;
    }).catch(err=>{
        alert("Houve erros na execução");
        console.log(err);
        return {};
    });

    return itens;
}

export const fetchProcedimentos=async ()=>{
    let auth=JSON.parse(localStorage.getItem('auth'));
    let itens = await fetch(`${URL_API}/produtos/procedimentos/odontologicos`,{
        headers:{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "X-Auth-Token":auth.token
        },
        method:'get'
    })
    .then(res=>res.json())
    .then((resJson)=>{
        return resJson;
    }).catch(err=>{
        alert("Houve erros na execução");
        console.log(err);
        return {};
    });

    return itens;
}

export const fetchDentes=async ()=>{
    let auth=JSON.parse(localStorage.getItem('auth'));
    let itens = await fetch(`${URL_API}/dentes/all`,{
        headers:{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "X-Auth-Token":auth.token
        },
        method:'get'
    })
    .then(res=>res.json())
    .then((resJson)=>{
        return resJson;
    }).catch(err=>{
        alert("Houve erros na execução");
        console.log(err);
        return {};
    });

    return itens;
}

export const fetchTratamentos=async (participante_id,agendamento_id)=>{
    let auth=JSON.parse(localStorage.getItem('auth'));

    
    async function loadNaoAutorizados(participante_id){
        let avaliacoes = await fetch(`${URL_API}/smartjob-beta/retornar-procedimentos-do-participante?part_id=${participante_id}`,{
            headers:{
                "Accept": "application/json",
                "Content-Type": "application/json",
                "X-Auth-Token":auth.token
            },
            method:'get'
        })
        .then(res=>res.json())
        .then((resJson)=>{
            if(resJson.err){
                alert(resJson.message);
                return [];
            }else{
                return resJson.result;
            }
        }).catch(err=>{
            alert("Houve erros na execução");
            console.log(err);
            return {};
        });

        let nao_autorizados = avaliacoes.filter(e=>e.autorizado===false);

        return nao_autorizados;
    }

    async function loadTratamentosAutorizadosEEvolucoes(participante_id,agendamento_id){
        let tratamentos = await fetch(`${URL_API}/prontuarios/${participante_id}/tratamentos?agendamento_id=${agendamento_id}`,{
            headers:{
                "Accept": "application/json",
                "Content-Type": "application/json",
                "X-Auth-Token":auth.token
            },
            method:'get'
        })
        .then(res=>res.json())
        .then((resJson)=>{
            if(resJson.status=='ok'){
                return resJson.data;
            }else{
                alert(resJson.mensagem);
                return {};
            }
        }).catch(err=>{
            alert("Houve erros na execução");
            console.log(err);
            return {};
        });

        return tratamentos;
    }

    const [nao_autorizados, tratamentos] = await Promise.all([
        loadNaoAutorizados(participante_id),
        loadTratamentosAutorizadosEEvolucoes(participante_id,agendamento_id)
    ]);

    return {
        autorizados:tratamentos.tratamentos,
        nao_autorizados:nao_autorizados,
        evolucoes:tratamentos.evolucoes
    };
}

export const fetchAddFoto = async(agendamento_id, file)=>{
    let auth=JSON.parse(localStorage.getItem('auth'));

    let addFoto = await fetch(`${URL_API}/smartjob-beta/participante/add-foto-procedimento-participante?ag_id=${agendamento_id}&pessoa_id=${auth.pessoa_id}`,{
        headers:{
            "Accept": "application/json, multipart/form-data, /*",
            // "Content-Type": "application/json, multipart/form-data",
            "X-Auth-Token":auth.token
        },
        method:'POST',
        body: file
    })
    .then(res=>res.json())
    .then((resJson)=>{
        if(resJson.err){
            alert(resJson.message);
            return [];

        }else{
            alert("Imagem enviada com sucesso!");
            return resJson.query;
        }
    }).catch(err=>{
        alert("Houve erros na execução");
        console.log(err);
        return {};
    });

    return addFoto;
}