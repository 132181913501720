import React from 'react';
import '../../css/ComponentCss/HeaderTitleModal.css';

import { useState,useEffect } from 'react';

export default function UiHeaderTitleModal(props){

    const [fechar, setFechar] = useState([]);
    const [agenda, setAgenda] = useState(false)
    
    useEffect(()=>{
        if(props.agenda){
            setAgenda(true)
        }  
    },[props.agenda])
    
    return(
        <div>
            <div className="header-title">
                <h5>{props.title?props.title:null}</h5>
                <div className="modal-footer width-close">
                    <a href="#" onClick={props.callback} className="modal-close waves-effect waves-green btn-flat"><i className="material-icons">close</i></a>
                </div>
            </div>
        </div>
    )
}