import {URL_API, URL_SITE} from '../../../constantes/constantes';
import {removerCredenciaisDeLogin} from '../../../functions/auth/authLogin';

const types={
    FETCH_INFORMACOES_BASICAS:"FETCH_INFORMACOES_BASICAS"
};

const INITIAL_STATE={
    informacoes_basicas:[]
};

export function profissional(state = INITIAL_STATE, action){
    switch(action.type){
        case types.FETCH_INFORMACOES_BASICAS:
            return  {...state, informacoes_basicas:action.payload};
        default:
            return state;
    }
}

// retornar informações básicas do profissional
export const fetchInformacoesBasicas=async (pessoa_id)=>{//pessoa_id
    // let pessoa_id=211;
    // let pessoa_id=216;
    // let pessoa_id=80;

    let auth=JSON.parse(localStorage.getItem('auth'));
    let informacoes = await fetch(`${URL_API}/smartjob-beta/app/informacoes-basicas-profissional/${pessoa_id}`,{
        headers:{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "X-Auth-Token":auth.token
        },
        method:'get'
    })
    .then(res=>res.json())
    .then((resJson)=>{
        if(resJson.err){
            alert(resJson.message);
            return {};
        }else{
            return resJson.data;
        }
    }).catch(err=>{
        alert("Houve erros na execução");
        console.log(err);
        return {};
    });

    return informacoes;
}