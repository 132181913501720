import {URL_API} from '../../../constantes/constantes';
import {removerCredenciaisDeLogin} from '../../../functions/auth/authLogin';

const types={
    FETCH_CHAMAR_PAINEL:"FETCH_CHAMAR_PAINEL",
    FETCH_VERIFICA_PAINEL:"FETCH_VERIFICA_PAINEL",
};

const INITIAL_STATE={
    verifica_painel:{},
    chamar_painel:{}
};

export function painel(state = INITIAL_STATE, action){
    switch(action.type){
        case types.FETCH_CHAMAR_PAINEL:
            return  {...state, chamar_painel:action.payload};
        case types.FETCH_VERIFICA_PAINEL:
            return {...state, verifica_painel:action.payload};
        default:
            return state;
    }
}

export const fetchVerificaPainel = async(agendamento)=>{

    let auth=JSON.parse(localStorage.getItem('auth'));

    let status_painel = await fetch(`${URL_API}/smartjob-beta/verifica-Painel`,{
        headers:{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "X-Auth-Token":auth.token
        },
        method:'post',
        body:JSON.stringify({ id:agendamento.agendamento_id })
    })
    .then(res=>res.json())
    .then((resJson)=>{
            if(resJson.err){
                alert(resJson.message);
                return [];
            }else{
                return resJson.result;
            }
        }).catch(err=>{
            alert("Houvera, erros na execução");
            console.log(err);
    });
    if(status_painel==null)
        console.log("ligado")
    else{
        console.log("desligado")
    }
    return status_painel;
}

export const fetchChamarPainel = async(agendamento)=>{
    let auth=JSON.parse(localStorage.getItem('auth'));

    let chamar_painel= await fetch(`${URL_API}/smartjob-beta/chamar-paciente-no-painel`,{
            headers:{
                "Accept": "application/json",
                "Content-Type": "application/json",
                "X-Auth-Token":auth.token
            },
            method:'post',
            body:JSON.stringify({ id:agendamento.agendamento_id, profissional_chamou:true})
        })
        .then(res=>res.json())
        .then((resJson)=>{
            if(resJson.err){
                alert(resJson.message);
                return resJson;
            }
        }).catch(err=>{
            alert("Houveram erros na execução");
            console.log(err);
        });

        return chamar_painel;
}
