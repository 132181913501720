import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import '../../../css/home.css'
import M from "materialize-css";

import ft_perfil from "../../../img/ft_perfil.png";

import '../Agendamentos/index.js'

import UiBotaoMenu from "../../../componentes/ui/BotaoMenu";
import UiHeader from "../../../componentes/ui/Header";
import UiCardStatus from "../../../componentes/ui/CardStatus";
import UiModalAgendamentos from "../../../componentes/ui/ModalAgendamentos";
import HomeModalAgendamentosPlanos from "../../../componentes/home/ModalAgendamentosPlano";
import UiHeaderTitleModal from "../../../componentes/ui/HeaderTitleModal";
import UiLoading from '../../../componentes/ui/Loading';

import {URL_API} from '../../../constantes/constantes';


import {agendamentosPorPlanos} from '../../../functions/Filtros/agendamentos';
import {filtroAgendamentos} from '../../../functions/Filtros/agendamentos';
import {filtroHome} from '../../../functions/Filtros/home';
import {filtroFoto} from '../../../functions/Filtros/filtros';

import {fetchAgendamentosDoDia} from '../../../store/reducers/agendamentos';
import {fetchInformacoesBasicas} from '../../../store/reducers/profissional';
 

export default function ScreensAutorizadoHome(){

    const reduxAgendamentos=useSelector(state=>state.agendamentos.agendamentos);
    const reduxProfisional=useSelector(state=>state.profissional.informacoes_basicas);
    const dispatch = useDispatch();
    
    const [ agendamentos, setAgendamentos ] = useState([]);
    const [ profissional, setProfissional ] = useState({});
    const [ agendamentosConvenios, setAgendamentosConvenios ] = useState([]);
    const [ visualizarConvenio, setVisualizarConvenio ] = useState("");
    const [fotoPerfil, setFotoPerfil] = useState([]);
    const [agendamentoChamarPainel, setAgendamentoChamarPainel] = useState({});
    // loading
    const [loadingArr, setLoadingArr] = useState([]);


    // só executa uma unica vez ao carregar o component
    useEffect(()=>{
        
        let auth= JSON.parse(localStorage.getItem('auth'));
        
        async function loadAgendamentos(){
            M.AutoInit();
            let auth=JSON.parse(localStorage.getItem('auth'));
            
            let data = new Date();

            incluirLoading('buscandoAgendamentoHome');

            let [agendamentos, profissional] = await Promise.all([
                fetchAgendamentosDoDia(`${data.getFullYear()}-${(data.getMonth()+1)}-${data.getDate()}`),
                fetchInformacoesBasicas(auth.pessoa_id)
            ]);

            excluirLoading('buscandoAgendamentoHome');

            dispatch({type:'FETCH_AGENDAMENTOS_DIA', payload:agendamentos});
            dispatch({type:'FETCH_INFORMACOES_BASICAS', payload:profissional});
        }

        loadAgendamentos();

    },[]);

    // executa quando reduxAgendamentos mudar
    useEffect(()=>{
        let planos = filtroAgendamentos(reduxAgendamentos);
        setAgendamentos(reduxAgendamentos);
        setAgendamentosConvenios(planos.filtrosValores.turnoArray);
    },[reduxAgendamentos]);

    useEffect(()=>{
        setProfissional(reduxProfisional);
    },[reduxProfisional]);

    useEffect(() => { 
        if(agendamentos){
            if(agendamentos.length>0){
                let filtros = filtroHome(agendamentos);
            }
        }
    },[]);

    useEffect(()=>{
        let ft = filtroFoto(profissional.path_foto);
        setFotoPerfil(ft);
    },[profissional.path_foto])

    function handlerChamarpainel(agendamento){
        setAgendamentoChamarPainel(agendamento);
    }

    async function chamarPainel(agendamento){
        let auth=JSON.parse(localStorage.getItem('auth'));

        incluirLoading('chamarPacientePainel');

        await fetch(`${URL_API}/smartjob-beta/chamar-paciente-no-painel`,{
            headers:{
                "Accept": "application/json",
                "Content-Type": "application/json",
                "X-Auth-Token":auth.token
            },
            method:'post',
            body:JSON.stringify({ id:agendamento.agendamento_id })
        })
        .then(res=>res.json())
        .then((resJson)=>{
            if(resJson.err){
                alert(resJson.message);
            }
        }).catch(err=>{
            alert("Houveram erros na execução");
            console.log(err);
        });

        excluirLoading('chamarPacientePainel');
    }

    function handlerConvenio(turno){
        setVisualizarConvenio(turno);
    }

    function incluirLoading(id){
        let arr = loadingArr;
        arr.push(id);
    }

    function excluirLoading(id){
        let arr = loadingArr.filter(e=>e!=id);

        setLoadingArr(arr);
    }
    
    return(
        <div>
            { loadingArr.length>0 && <UiLoading type="bubbles" color="#1976de" />}
            <UiHeader/>
            <UiBotaoMenu/>
            <UiModalAgendamentos incluirLoading={incluirLoading} excluirLoading={excluirLoading} />

            <div className="conteudo">

                {/*inicio area superior*/}
                <div className="top-dash">
                    <div className="top-dash-inner">
                        <div className="row row-top">
                            <div className="col s12 col-top">
                                <div className="card-panel card-panel-top black-text">
                                    <div className="top-dash-info">
                                        <div className="ft-dash-cont"><img className="foto-dash foto perfil" src={`http://smartjob.bemestarcmo.com.br/api/public/${fotoPerfil}`}/></div>
                                        <div className="top-dash-info-inner">
                                            <span><b>{profissional?profissional.apelido:null}</b></span>
                                            <span>{profissional?profissional.cpf:null}</span>
                                        </div>
                                        <div className="consultorio">
                                            <span className="consultorio-num">C - {profissional?profissional.numero:null}</span>
                                        </div>
                                    </div>

                                    <div className="top-dash-infoBottom">
                                        <a href="#modalClientesConf" className="modal-trigger black-text">
                                            <span>Agenda do Dia {(new Date()).getDate()}/{((new Date()).getMonth()+1)<10?"0"+((new Date()).getMonth()+1):((new Date()).getMonth()+1)}/{(new Date()).getFullYear()}</span>
                                            <span>{agendamentos.length} Agendados<i className="material-icons">expand_more</i></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*fim area superior*/}


                {/*inicio area corpo(cards)*/}
                <div className="body-dash">
                    <div className="body-dash-inner">
                        <div className="lista-body">
                            <ul className="collection body-dash-collection">
                                {
                                    agendamentosConvenios.map((e,index)=>{
                                        return <a href="#modalDetalheConvenio" onClick={()=>{handlerConvenio(index)}} key={e[0].turno} className="collection-item waves-effect waves-light modal-trigger"><b>{e.length}</b> {e[0].turno.toLowerCase()}</a>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                {/*fim area corpo*/}

                {/*inicio modal2*/}
                <HomeModalAgendamentosPlanos callback={handlerChamarpainel} agendamentosDoConvenio={agendamentosConvenios[visualizarConvenio]} title={"turno"}/>
                {/*fim modal2 */}

                <div id="modal1" className="modal">
                    <div className="modal-content">
                    <h4>Smartjob informa</h4>
                    <p>Deseja chamar o paciente para o consultório?</p>
                    </div>
                    <div className="modal-footer">
                        <a href="#!" className="modal-close waves-effect waves-green btn-flat">Não</a>
                        <a href="#" onClick={()=>{chamarPainel(agendamentoChamarPainel)}} className="modal-close waves-effect waves-green btn-flat">Sim</a>
                    </div>
                </div>

                {/*inicio modal3*/}
                <div id="modalClientesConf" className="modal bottom-sheet">
                    <div className="modal-content">
                        <div className="drop-infos-dash">
                            <UiHeaderTitleModal title={(`${(new Date()).getDate()}/${((new Date()).getMonth()+1)<10?"0"+((new Date()).getMonth()+1):((new Date()).getMonth()+1)}/${(new Date()).getFullYear()}`)} />
                            
                            <div className="select-option-dash">{/*select*/}
                                <div className="input-field col col-option plano-col s12">
                                    <select defaultValue={1|''} className="select-option plano-select">
                                        <option value="" disabled>Plano</option>
                                        <option value="1">Hapvida</option>
                                        <option value="2">Rede unna</option>
                                        <option value="3">Particular</option>
                                    </select>
                                </div>
                                <div className="input-field col col-option espec-col s12">
                                    <select defaultValue={1|''} className="select-option espec-select">
                                        <option value="" disabled>Especialidade</option>
                                        <option value="1">Clinico Geral</option>
                                        <option value="2">Ortodontia</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    
                        {
                            agendamentos.length>0?agendamentos.map((e,index)=>{
                                return <UiCardStatus key={e.agendamento_id} agendamento={[e]}/>
                            }):null
                        }
                    </div>
                    <div className="modal-footer">
                        <a href="#!" className="modal-close waves-effect waves-green btn-flat">Fechar</a>
                    </div>
                </div>
                {/*fim modal3*/}
            </div>
        </div>
   );
}