import React from 'react';
import smartjob_logomarca from '../../img/smartjob_logomarca.png';

import ft_perfil from '../../img/ft_perfil.png';

import {logout} from '../../functions/auth/authLogin';

export default function UiHeaderSimple(){
    return(

        <div>
            <nav className="nav">
                <div className="nav-wrapper">
                    {/* <a href="#" data-target="slide-out" className="sidenav-trigger"><i className="material-icons">menu</i></a> */}
                    <img src={smartjob_logomarca} className="nav-brand" alt="logo smart Job"/>
                    {/*<a><i className="material-icons right">search</i></a>*/}
                    <ul id="nav-mobile" className="right hide-on-med-and-down">
                        <li><a href="sass.html">Sass</a></li>
                        <li><a href="badges.html">Components</a></li>
                        <li><a href="collapsible.html">JavaScript</a></li>
                    </ul>
                </div>
            </nav>

            <ul id="slide-out" className="sidenav">
                <li>
                    <div className="user-view">
                        <div className="background back-nav"></div>
                        <a href="#user"><img className="circle" alt="foto de perfil" src={ft_perfil} /></a>
                        <a href="#name"><span className="black-text name">John Doe</span></a>
                        <a href="#email"><span className="black-text email">jdandturk@gmail.com</span></a>
                    </div>
                </li>
                <li><a href="#"><i className="material-icons">cloud</i>First Link With Icon</a></li>
                <li><a href="#">Second Link</a></li>
                <li><div className="divider"></div></li>
                <li><a className="subheader">Subheader</a></li>
                <li><a className="waves-effect" href="#">Third Link With Waves</a></li>
                <li><a className="waves-effect" onClick={logout}>Sair</a></li>
            </ul>
    </div>
    );
}