import React,{useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import M from "materialize-css";

import '../../css/geral.css';
import '../../css/ComponentCss/Cardstatus.css';
import '../../css/agendamentos.css'

import {filtroHora} from '../../functions/Filtros/filtros';
import {filtroIdade} from '../../functions/Filtros/filtros';
import { StatusCores, bordeCardColor } from '../../functions/Filtros/agendamentos';

import { agendamentos } from '../../store/reducers/agendamentos';


export default function UiCardStatus (props){

    const statusAtendimento =[
        {nome:'Marcado',abrev:"M"},
        {nome:"Confirmado", abrev:"C"},
        {nome:"Agenda Excluida",abrev:"E"},
        {nome:"Pendência Recebimento", abrev:"P"},
        {nome:"Fila de Espera",abrev:"F"},
        {nome:"Sala Profissional", abrev:"S"},
        {nome:"Término de Atendimento",abrev:"T"},
        {nome:"Não Atendido", abrev:"T"},
        {nome:"Ausente",abrev:"A"},
        {nome:"Ocorrência", abrev:"O"}
    ];

    const dispatch = useDispatch();
    let [agendamento,setAgendamento] = useState(props.agendamento[0]?props.agendamento[0]:null);
    const [HoraFormatada, setFiltroHora] = useState(agendamento.inicio);
    const [HoraChegada, setHoraChegada] = useState(agendamento.status_cadastro)/* ck_data_hora_inicio */
    const [calculaIdade, setFiltroIdade] = useState();

    // useEffect(()=>{
    //     M.AutoInit();
    // },[])

    useEffect(()=>{

        let horaFormat = filtroHora(agendamento.inicio);
        setFiltroHora(horaFormat);

        if(agendamento.status_cadastro){/* ck_data_hora_inicio */
            let ck_dt = agendamento.status_cadastro.split(" ")[1];/* ck_data_hora_inicio */

            let horaCheck = filtroHora(ck_dt);
            setHoraChegada(horaCheck);    
        }

        if(agendamento.data_nascimento){
            let calIdade = filtroIdade(agendamento.data_nascimento)
            setFiltroIdade(calIdade);
        }
    },[])

    const dispatchDetalheAgendamento=(agendamentoDetalhe)=>{
        dispatch({type:'FETCH_DETALHE_AGENDAMENTO', payload:agendamentoDetalhe});
    }
    
    
    function handlerCallback(agendamento){
        if(props.callback)
        props.callback(agendamento);
    }
    
    function isErroIniciaAtendimento(agendamento) {
        let auth=JSON.parse(localStorage.getItem('auth'));
        let me_id = auth.pessoa_id;

        return agendamento !== null && agendamento.inicia_atendimento !== 'A2' && agendamento.status !== 7 || (me_id != agendamento.pessoa_id && agendamento.chamar_painel_qt>0) || (agendamento.prioridade == 1 && me_id != agendamento.pessoa_id);

        //se o paciente não for "meu" e já tiver sido chamado(está em ocorrencia (com outro profissional)) então "eu"/medic@ não posso chama-lo/pegar o paciente pra mim ^. Ou se for retorno imediato, śo volta pro profissional que atendeu antes
    };

//INICIO muda a cor do horário caso tempo do paciente na fila de espera passe de 1 hora
    function mudaCor(){ 
        let data = new Date();
        let atualHora= (`${data.getHours()}${data.getMinutes()>10?data.getMinutes():("0"+data.getMinutes())}`); 

        if(agendamento.status_cadastro){/*ck_data_hora_inicio as vezes esse campo fica com o horário errado (pode ser devido o pc da recepção registrar o horário errado?) */
            let hrCheckin = agendamento.status_cadastro.split(" ");/* ck_data_hora_inicio */
            hrCheckin = hrCheckin[1];
            hrCheckin = hrCheckin.split(":");
            hrCheckin = hrCheckin[0] + hrCheckin[1];
    
            let espera = (atualHora - hrCheckin);
    
            if (espera >= 100 && agendamento.status == 5 && agendamento.chamar_painel_qt<1) {
                return "colorMaior";
            }else{
                return "colorMenor";
            }   

        }
    }
//FIM muda a cor do horário caso tempo do paciente na fila de espera passe de 1 hora

    

    return(
        <div>
            <div className={` card ${bordeCardColor(agendamento)} `}>
            {/* <div className={` card cardBorder ${StatusCores(agendamento)} `}> */}{/*rodando localmente, dessa forma funciona, mas não funciona no beta  */}

                <div className="card-content">

                    <a className="dropdown-trigger icones-a icon-ponto" href="#" data-target="drop-card-status">
                        <i className="material-icons left">more_vert</i>
                    </a>        
                    {/* Dropdown Structure */}
                    <ul id="drop-card-status" className="dropdown-content drops-info">
                        <li><a className="modal-trigger card-link" onClick={()=>{dispatchDetalheAgendamento(agendamento)}} href="#modalAgendamentos">incluir plano de tratamento</a></li>{/* href="#" */}
                    </ul>
                    <a className="modal-trigger card-link" onClick={()=>{dispatchDetalheAgendamento(agendamento)}} href="#modalAgendamentos">
                        <div className="inner">
                            <div className="card-info">
                                <div>  
                                    <span className="card-title black-text">{agendamento?agendamento.razao_social:null}</span>
                                    <div>
                                        <span className="card-title black-text">CPF:{agendamento?agendamento.documento:null}</span>
                                        { agendamento.data_nascimento?
                                            <span className="card-title black-text">IDADE: {agendamento.data_nascimento?calculaIdade:null} </span>
                                            :null
                                        }
                                    </div>
                                    <div>
                                        <span className="card-title black-text">{agendamento?agendamento.desc_sub_grupo:null}</span>
                                        {/* <span className="card-title black-text">{agendamento?agendamento.convenio:null}</span>   */}
                                        {
                                            agendamento.prioridade > 0?
                                                agendamento.prioridade != 1?
                                                    <span className="card-title red-text">PRIORIDAE</span>  
                                                :<span className="card-title red-text">RETORNO</span>  
                                            :null
                                        }
                                    </div>
                                    <div>
                                        <span className="card-title black-text">{agendamento?HoraFormatada:null}</span>
                                        {
                                            agendamento.status == 5 ||agendamento.status == 6 ||agendamento.status == 8 /* || agendamento.status == 10  */?
                                                <span className={`card-title ${mudaCor(props)}`}><b>Chegada: {agendamento?HoraChegada:null}</b></span>  
                                            :null
                                        }
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </a>
                    <div className="status-info">
                        <a style={{marginBottom:10}} 
                            onClick={()=>{handlerCallback(agendamento)}} 
                            className={`waves-effect waves-light btn-floating transparent modal-trigger 
                                ${(isErroIniciaAtendimento(agendamento)||!agendamento.info_paciente_atualizada||!agendamento.ck_concluido ||![4,5,6].includes(agendamento.status))?"disabled":"blue darken-3"} `} 
                            href="#modal1"><i className="material-icons left">notifications</i>
                        </a>
                        {/* <span className={`badge badge-btn ${StatusCores(agendamento)}`}>{agendamento?statusAtendimento[(agendamento.status-1)].abrev:null}</span> */}
                        <span data-tooltip="n° de vezes que paciente foi chamado" className="num">{agendamento.chamar_painel_qt?agendamento.chamar_painel_qt:null}</span>
                    </div>
                </div>
                <div>
                    <span style={{display:'flex', justifyContent:'center', padding:'5px'}} className={`${StatusCores(agendamento)}`}>{agendamento?statusAtendimento[(agendamento.status-1)].nome:null}</span>
                </div>
            </div>
        </div>
    );  
}