import React, {useEffect, useState} from 'react';
import UiCardStatus from '../ui/CardStatus';
import UiHeaderTitleModal from '../ui/HeaderTitleModal';
import UiHeaderSimple from '../ui/HeaderSimple.js';

export default function HomeModalAgendamentosPlanos(props){

    const tiposAtendimento = ["avaliação", "atendimento", "retorno médico", "avaliação orto", "manutenção orto"];
    const statusAtendimento = ["marcado", "confirmado", "agenda excluída", "pendência recebimento", "na fila para atendimento", "sala do profissional", "término de atendimento", "não atendido", "ausente", "ocorrência"];
    const [agendamentos, setAgendamentos] = useState([]);
    const [title, setTitle] = useState([]);

    useEffect(()=>{
        if(props.agendamentosDoConvenio){
            setAgendamentos(props.agendamentosDoConvenio);

            let titulo = "";
            if(props.title=="status"){
                titulo = (props.agendamentosDoConvenio.length>0&&props.title!="todos")?eval(`statusAtendimento[(props.agendamentosDoConvenio[0].${props.title}-1)]`):null;
            }else if(props.title=="tipo"){
                titulo = (props.agendamentosDoConvenio.length>0&&props.title!="todos")?eval(`tiposAtendimento[(props.agendamentosDoConvenio[0].${props.title}-1)]`):null;
            }else{
                titulo = (props.agendamentosDoConvenio.length>0&&props.title!="todos")?eval(`props.agendamentosDoConvenio[0].${props.title}`):null;
            }

            setTitle(titulo);
        }
    },[props.agendamentosDoConvenio]);


    return (
        <div id="modalDetalheConvenio" className="modal bottom-sheet">
            <UiHeaderSimple/>
            <div className="modal-content">
                <UiHeaderTitleModal title={title} modal="teste2" /> 
                {
                    agendamentos.length>0?agendamentos.map(e=>{
                        return <UiCardStatus callback={(data)=>{if(props.callback)props.callback(data)}} key={e.agendamento_id} agendamento={[e]}/>
                    }):null
                }
            </div>
            <div className="modal-footer">
                <a href="#" className="modal-close waves-effect waves-green btn-flat">Fechar</a>
            </div>
        </div>
    )
}