import {URL_API, URL_SITE} from '../../../constantes/constantes';
import {removerCredenciaisDeLogin} from '../../../functions/auth/authLogin';

const types={
    INCLUDE_LOADING:"INCLUDE_LOADING",
    EXCLUDE_LOADING:"EXCLUDE_LOADING",
};

const INITIAL_STATE={
    loading:[],
};

export function configuracao(state = INITIAL_STATE, action){
    switch(action.type){
        case types.INCLUDE_LOADING:
            return  {...state, loading:action.payload};
        case types.EXCLUDE_LOADING:
            return  {...state, loading:action.payload};
        default:
            return state;
    }
}