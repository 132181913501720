// const URL_API_PRODUCAO = 'http://smartjob-agenda.bemestarcmo.com.br/api-agenda/api/public';
const URL_API_BETA = 'http://beta-smartjob.bemestarcmo.com.br/smartjob/api/public';
// const URL_API_LOCAL = 'http://localhost:8089';

// const URL_SITE_PRODUCAO = 'http://smartjob-app.bemestarcmo.com.br:3000';
// const URL_SITE_PRODUCAO = 'http://smartjob-app.bemestarcmo.com.br';
const URL_SITE_BETA = 'http://smartjob-appbeta.bemestarcmo.com.br';
// const URL_SITE_LOCAL = 'http://smartjobpwa.ddns.net:3000';
// const URL_SITE_LOCAL = 'http://localhost:3000';



export const URL_SITE= URL_SITE_BETA;
// export const URL_SITE= URL_SITE_LOCAL;
// export const URL_SITE= URL_SITE_PRODUCAO;


export const URL_API= URL_API_BETA;
// export const URL_API= URL_API_LOCAL;    
// export const URL_API= URL_API_PRODUCAO; 

