import {URL_SITE, URL_API} from '../../constantes/constantes';


export const isAutenticated = ()=>{
    let auth=localStorage.getItem("auth");
    if(typeof auth !== 'undefined' && auth !== null){
        return true;
    }else{
        return false;
    }
    // return true;
}

export const removerCredenciaisDeLogin=()=>{
    localStorage.removeItem("auth");
    localStorage.removeItem("c");
    localStorage.removeItem("empresa");
    localStorage.removeItem("empresas");
    localStorage.removeItem("menus");
}

export const logout= async ()=>{
    let token = JSON.parse(localStorage.getItem("auth")).token;

    await fetch(`${URL_API}/auth/sair`,{
        headers:{
            'Accept':'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        },
        method:'POST',
        body:JSON.stringify({token:token})
    })
    .then((res)=>res.json())
    .then(async (response)=>{

        removerCredenciaisDeLogin();

        if(response.err){
            alert(response.message);
        }
    });

    window.location.replace(`${URL_SITE}/`);
}