import React from 'react';

import { BrowserRouter, Switch, Route, Redirect, HashRouter } from 'react-router-dom';
import { URL_SITE} from '../constantes/constantes';


import ScreensAuthLogin from './Auth/Login';
import ScreensAutorizadoCheckin from './Autorizado/Checkin';
import ScreensAutorizadoHome from './Autorizado/Home';
import ScreensAutorizadoAgendamentos from './Autorizado/Agendamentos';
import ScreensAutorizadoRepasses from './Autorizado/Repasses';

import { isAutenticated } from "../functions/auth/authLogin";

const PrivateRoute = ({component: Component, ...rest})=>(
    console.log(rest.path,'rest'),
    <Route 
        {...rest}
        render={props=>
            isAutenticated()?(
                <Component {...props} />
                ) : (
                    <Redirect to={{ pathname:"/", state:{ from: props.location } }} />
                )
        }
        />
);

const LoginRoute = ({component: Component, ...rest})=>(
    <Route 
        {...rest}
        render={props=>
            !isAutenticated()?(
                <Component {...props} />
            ) : (   
                    <Redirect to={{ pathname:"/user/agendamentos", state:{ from: props.location } }} />
                )
        }
    />
);


function RouterApp(){
    return (
        <div>
            <BrowserRouter>
                <Switch>
                    <LoginRoute exact path="/" component={ScreensAuthLogin} /> 
                    <LoginRoute path="/user/login" component={ScreensAuthLogin} />
                    <PrivateRoute path="/user/home" component={ScreensAutorizadoHome} />
                    <PrivateRoute path="/user/checkin" component={ScreensAutorizadoCheckin} />
                    <PrivateRoute path="/user/agendamentos" component={ScreensAutorizadoAgendamentos}/>
                    <PrivateRoute path="/user/repasses" component={ScreensAutorizadoRepasses}/>
                    <PrivateRoute path="*" component={()=><h1>page not found</h1>} />
                </Switch>
            </BrowserRouter> 
        </div>
    )
}


export default RouterApp;
